import {
	ContentCopy,
	Delete,
	GetApp,
	Notifications,
	Print,
	VisibilityOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { IconBtn } from 'common/components/IconBtn';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import {
	UserInvestorPermissionOptions,
	UserInvestorPermissionsWrapper,
} from 'common/helpers/permissions/UserInvestorPermissionsWrapper';
import {
	IActionButtonsProps,
	TDocumentNotificationWrapperProps,
	alloweDocumentTypesToManualNotifications,
} from './types';

const DocumentNotificationWrapper = ({
	children,
	documentType,
}: TDocumentNotificationWrapperProps): JSX.Element => {
	return <></>;

	// ----------------------------------------------- //
	// UNCOMMENT FOR LPX-957
	// ----------------------------------------------- //
	// if (
	// 	!documentType ||
	// 	!alloweDocumentTypesToManualNotifications.includes(documentType)
	// )
	// 	return <></>;

	// return <>{children}</>;
};

export const ActionButtons = ({
	document,
	onDelete,
	onRedirect,
	onDownload,
	onPrint,
	onUrlCopy,
	onNotify,
}: IActionButtonsProps) => (
	<Box style={{ padding: '5px', textAlign: 'end' }}>
		<IconBtn
			customColor={true}
			onClick={(e) => {
				e.stopPropagation();
				onRedirect(document);
			}}
			tooltip="View"
		>
			<VisibilityOutlined sx={{ width: '24px', height: '24px' }} />
		</IconBtn>

		<UserInvestorPermissionsWrapper
			ability={UserInvestorPermissionOptions.canDownload}
		>
			<IconBtn
				customColor={true}
				onClick={(e) => {
					e.stopPropagation();
					onDownload(document);
				}}
				tooltip="Download"
			>
				<GetApp sx={{ width: '24px', height: '24px' }} />
			</IconBtn>
		</UserInvestorPermissionsWrapper>

		<UserInvestorPermissionsWrapper
			ability={UserInvestorPermissionOptions.canPrint}
		>
			<IconBtn
				customColor={true}
				onClick={(e) => {
					e.stopPropagation();
					onPrint(document);
				}}
				tooltip="Print"
			>
				<Print sx={{ width: '24px', height: '24px' }} />
			</IconBtn>
		</UserInvestorPermissionsWrapper>

		<IconBtn
			customColor={true}
			onClick={(e) => {
				e.stopPropagation();
				onUrlCopy(document);
			}}
			tooltip="Copy URL"
		>
			<ContentCopy sx={{ width: '24px', height: '24px' }} />
		</IconBtn>

		<PermissionsWrapper scopes={[SCOPES.canDeleteDocument]}>
			<IconBtn
				customColor={true}
				onClick={(e) => {
					e.stopPropagation();
					onDelete(document);
				}}
				tooltip="Delete"
			>
				<Delete sx={{ width: '24px', height: '24px' }} />
			</IconBtn>
		</PermissionsWrapper>

		<PermissionsWrapper scopes={[SCOPES.canNotifyOnDocuments]}>
			<DocumentNotificationWrapper documentType={document.type}>
				<IconBtn
					customColor={true}
					onClick={(e) => {
						e.stopPropagation();
						onNotify(document);
					}}
					tooltip="Notify"
				>
					<Notifications sx={{ width: '24px', height: '24px' }} />
				</IconBtn>
			</DocumentNotificationWrapper>
		</PermissionsWrapper>
	</Box>
);
