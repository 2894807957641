import { FC, useEffect, useCallback, useMemo } from 'react';
import { MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import { onChangeInvestor, selectDropdown } from 'api/redux/DropdownReducer';
import { DropdownFormControl, InvestorSelectControl } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { ERoutes } from 'App';
import { IInvestorGrant } from 'api/redux/services/userApi';
import { onSetSelectedDocTypeIds } from 'api/redux/DocumentsReducer';
import { SelectInvestorButton } from './SelectInvestorButton';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import {
	getAllowedInvestors,
	handleInvestorViewPageLogic,
	handleInvestorFundSync,
} from './Helpers/InvestorDropdownHelpers';

interface IDynamicInvestors {
	allowedInvestors: IInvestorGrant[];
}

const DynamicInvestors: FC<IDynamicInvestors> = ({ allowedInvestors }) => {
	const grants = useSelector(selectDropdown);
	const dispatch = useDispatch();
	const { currentInvestor } = grants.grants;
	const { pathname } = useLocation();
	const isInvestorViewPage = !!matchPath(pathname, ERoutes.InvestorsView);

	const currentValue = useMemo(() => {
		if (!currentInvestor?.id && currentInvestor?.id !== 0) return '';
		return currentInvestor.id.toString();
	}, [currentInvestor?.id]);

	const handleInvestorChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			const selectedInvestorId = Number(event.target.value);
			const selectedInvestor = allowedInvestors.find(
				(investor) => investor.id === selectedInvestorId,
			);
			if (selectedInvestor) {
				dispatch(onChangeInvestor(selectedInvestor));
			}
		},
		[allowedInvestors, dispatch],
	);

	return (
		<DropdownFormControl
			variant="outlined"
			size="small"
			style={{ margin: 5, width: '300px' }}
			title={currentInvestor.name}
		>
			<InputLabel id="investor-selector" style={{ color: '#F1F2F9' }}>
				Investor
			</InputLabel>
			<InvestorSelectControl
				labelId="investor-selector"
				id="investor-selector"
				value={currentValue}
				onChange={handleInvestorChange}
				label="Investor"
				isInvestorViewPage={isInvestorViewPage}
			>
				{allowedInvestors.map((investor) => (
					<MenuItem key={investor.id} value={investor.id.toString()}>
						{investor.name}
					</MenuItem>
				))}
			</InvestorSelectControl>
		</DropdownFormControl>
	);
};

export const InvestorDropdown: FC = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const { isAdmin } = useGrants();
	const { grants } = useSelector(selectDropdown);
	const { showAllUsers } = useSelector(selectDropdown);
	const {
		availableInvestors,
		allAvailableInvestors,
		currentFund,
		firstDropdown,
		currentInvestor,
	} = grants;

	const isInvestorViewPage = useMemo(
		() => !!matchPath(pathname, ERoutes.InvestorsView),
		[pathname],
	);

	useEffect(() => {
		if (!currentFund.id && !currentInvestor.id) return;
		handleInvestorFundSync(
			isInvestorViewPage,
			currentFund,
			currentInvestor,
			dispatch,
		);
	}, [isInvestorViewPage, currentFund.id, currentInvestor.id, dispatch]);

	const allowedInvestors = useMemo(
		() =>
			getAllowedInvestors(
				isInvestorViewPage,
				showAllUsers,
				firstDropdown,
				availableInvestors,
				allAvailableInvestors,
				currentFund,
			),
		[
			isInvestorViewPage,
			showAllUsers,
			firstDropdown,
			availableInvestors,
			allAvailableInvestors,
			currentFund,
		],
	);

	if (allowedInvestors.length < 1) return <></>;

	if (isInvestorViewPage && showAllUsers) {
		return <SelectInvestorButton />;
	}

	if (isInvestorViewPage && !showAllUsers) {
		const viewPageInvestors = handleInvestorViewPageLogic(
			allAvailableInvestors,
			currentInvestor,
			isAdmin,
			dispatch,
		);
		return <DynamicInvestors allowedInvestors={viewPageInvestors} />;
	}

	return <DynamicInvestors allowedInvestors={allowedInvestors} />;
};
