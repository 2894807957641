import { constants } from 'common/helpers/Constants';

export const privacyPolicyText =
	'Privacy Policy' +
	'\n\n' +
	'LPx, Inc. (“LPx, “we,” “us,” or “our”) is committed to protecting the privacy of our users’ information. The following policy (the “Privacy Policy”) describes how we collect, use, and disclose information we receive from users of our website and services (collectively, the “Services”). This Privacy Policy describes our policies and procedures with respect to Personal Data (as defined below) collected, held, used or processed by us when you use the Services; the measures taken to protect the confidentiality, integrity and security of that personal data; and your rights regarding such Personal Data.' +
	'\n\n' +
	'By creating an account or otherwise visiting or using the Services, you acknowledge that you have read and agree to this Privacy Policy, which may be updated by us at any time without notice to you. If we update this Privacy Policy, we will post the updated version here. Any such changes will be effective when you first access the Services after we post the changes here. If we make any material changes in the way we collect, hold, use, process or share your Personal Data, we will endeavor to provide you with appropriate notice before such changes take effect. For the purposes of compliance with the E.U. General Data Protection Regulation (“GDPR”), LPx is a data processor with respect to your Personal Data. We process Personal Data on behalf of the controller. As used in this Privacy Policy, the term “Personal Data” shall have the meaning given under the GDPR.' +
	'\n\n' +
	'Information Collected' +
	'\n\n' +
	'We collect different types of information from users through our Services.' +
	'\n\n' +
	'Account information. We collect information in order to create your account and to understand your specific needs in order to serve you better. Account information includes but is not limited to login credentials and email addresses.' +
	'\n\n' +
	'User Data. In the course of using our Services, you may electronically submit data or information to us (each, the “User Data”). This information will be transmitted to our servers solely for the purposes of completing the intended transaction. LPx does not see, review, share, distribute, or reference any such User Data except as explained in accordance with the LPxTerms of Use (the “TOU”) or as may be required by applicable law. In accordance with the TOU, LPx may access User Data only: (i) to provide the Services, (ii) to prevent or address service or technical problems, (iii) upon an explicit request from you in connection with customer support matters, (iv) for product development, or (v) as may be required by applicable law.' +
	'\n\n' +
	'Automated Information Collection' +
	'\n\n' +
	'When you access our Services, some information is collected automatically. For example, when you access one of our websites, we automatically collect your browser’s internet protocol (IP) address, your browser type, the type and identifier of the device you are using such as a personal computer or a mobile device, the website(s) you recently visited prior to accessing any web-based Services, the actions you take on our Services, and the content, features, and activities in which you participate on our Services.' +
	'\n\n' +
	'The information is collected automatically using technologies such as standard server logs, cookies, and web beacons. A “cookie” is a text file that websites send to a visitor’s computer or other Internet-connected device to uniquely identify the visitor’s browser or to store information or settings in the browser. A “web beacon,” also known as an Internet tag, pixel tag or clear GIF, links web pages to web servers and their cookies and may be used to transmit information collected through cookies back to a web server. We use this automatically-collected information to administer, operate, and improve our Services, including more effective and targeted marketing. You can stop certain types of cookies from being downloaded to your device by selecting the appropriate settings on your web browser. Most web browsers will tell you how to stop accepting new browser cookies, how to be notified when you receive a new browser cookie and how to disable existing cookies. You can find out how to do this for your particular browser by clicking “help” on your browser’s menu or by visiting www.allaboutcookies.org.' +
	'\n\n' +
	'Usage and Disclosure of Information' +
	'\n\n' +
	'General Uses and Disclosures. We use and share the information we collect from users for the purposes described below. When necessary, we will obtain your consent before using your data for these purposes.' +
	'\n\n' +
	'Currently, LPx’s servers, and therefore your data, are located in the United States. Personal Data from the E.U. may be transferred to, stored in and processed in, the United States, and transferred to and processed in other countries. If we transfer your Personal Data out of the E.U. to a country not deemed to provide an adequate level of Personal Data protection for purposes of applicable data protection laws such that additional safeguards are required, the transfer will be performed:' +
	'\n\n' +
	'•	pursuant to the recipient’s compliance with Standard Contractual Clauses; or' +
	'\n' +
	'•	as otherwise permitted by applicable laws.' +
	'\n\n' +
	'In addition, we may use your personal data for the following purposes:' +
	'\n\n' +
	'•	Provision of Services to Website Users. If you visit our website, we will use your information to process and respond to your requests, comments, inquiries, and other information you submit through our website.' +
	'\n\n' +
	'•	Improving our Services. We use your activity and auto-collected information to better understand your preferences and constantly improve our Services.' +
	'\n\n' +
	'•	Disclosures to Service Providers. We only share your Personal Data with third-party service providers that are necessary for us to maintain and provide functionality to LPx’s website and applications, including email services. We share only the minimum amount of Personal Data that these service providers absolutely need to perform their functions.' +
	'\n\n' +
	'•	Compliance with Legal Obligations. We will share your information with law enforcement, government officials, regulatory agencies, or other third parties when we are required to do so by applicable law. We will also disclose your information to comply with a judicial proceeding, court order, subpoena or other legal process.' +
	'\n\n' +
	'•	Protection of Individual’s Vital Interests. In exigent circumstances, we will use or share your Personal Data when doing so is necessary to protect an interest that is essential for an individual’s life.' +
	'\n\n' +
	'•	Other Legitimate Interests. We will use and disclose your Personal Data when necessary for LPx’s legitimate interests as long as such interests are not overridden by your interests, rights, and freedoms with respect to Personal Data.' +
	'\n\n' +
	'Withdrawing Your Consent' +
	'\n\n' +
	`At any time, you may withdraw your consent to LPx for using, disclosing, or otherwise processing your Personal Data. You may withdraw your consent by emailing LPx at ${constants.SUPPORT_EMAIL}, and following the instructions in our communications to you.` +
	'\n\n' +
	'Please note that your withdrawal of consent to use certain Personal Data about you: (1) may limit our ability to deliver certain benefits and services to you, and (2) does not affect the legality of our processing activities based on your consent prior to your withdrawal.' +
	'\n\n' +
	'Your Rights' +
	'\n\n' +
	'Under the GDPR, you have the right to:' +
	'\n\n' +
	'•	access the Personal Data we have about you;' +
	'\n' +
	'•	be provided with information about how we process your Personal Data;' +
	'\n' +
	'•	object to or restrict how we process your Personal Data;' +
	'\n' +
	'•	correct any Personal Data we have you believe to be incorrect;' +
	'\n' +
	'•	require us to erase the Personal Data we have about you; and' +
	'\n' +
	'•	request the transfer of your Personal Data to a designated third party.;' +
	'\n\n' +
	`To exercise any of the rights above, please contact us at ${constants.SUPPORT_EMAIL} with your request within a reasonable period of time. Please be aware that under certain circumstances, the GDPR may limit your exercise of these rights.` +
	'\n\n' +
	'Retention of Personal Data' +
	'\n\n' +
	'We will retain your Personal Data only as long as necessary to process requests or other submissions, fulfill the terms of our service contract(s) with you and comply with applicable law.' +
	'\n\n' +
	'Where we process Personal Data to comply with our legal obligations, we will retain the Personal Data for as long as the customer continues to be a customer of LPx, and for 5 years from the date you are no longer a customer of LPx or as otherwise required under applicable law.' +
	'\n\n' +
	'Security of Personal Data' +
	'\n\n' +
	'No data transmitted over or accessible through the internet can be fully secure. While we attempt to protect all Personal Data, we cannot ensure or warrant that any Personal Data will be completely secure from misappropriation by hackers or criminal activities, or a failure of computer hardware, software, or a telecommunications network. We will notify you upon becoming aware of a security breach involving your Personal Data stored by or for us as soon as reasonably possible.' +
	'\n\n' +
	'Filing a Complaint' +
	'\n\n' +
	'You may file a complaint regarding this Privacy Policy or our privacy practices by contacting us. Additionally, you may file a complaint with the EU data protection authorities (the “DPAs”). Please contact us to be directed to the appropriate DPA contact(s).' +
	'\n\n' +
	'Comments and Questions' +
	'\n\n' +
	`If you have a comment, question, or request related to this Privacy Policy, please contact us at  ${constants.SUPPORT_EMAIL}.` +
	'\n\n' +
	'Dated January 2022' +
	'\n\n';
