import { FC } from 'react';
import { ITagsListProps } from './types';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	styled,
	Typography,
} from '@mui/material';
import { OptionsColumnBox } from './styles';
import _ from 'lodash';
import { SelectAllOption } from 'common/components/SelectAllOption';
import { useGetTagsQuery } from 'api/redux/services/tags.service';

interface StyledOptionsColumnBoxProps {
	disabled: boolean;
}

const StyledOptionsColumnBox = styled(OptionsColumnBox, {
	shouldForwardProp: (prop) => prop !== 'disabled',
})<StyledOptionsColumnBoxProps>(({ disabled }) => ({
	opacity: disabled ? 0.5 : 1,
}));

export const TagsList: FC<ITagsListProps> = ({
	selectedTagIds,
	setSelectedTagIds,
	disabled,
}) => {
	const grants = useSelector(selectDropdown);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const { currentSponsor } = grants.grants;
	const { data: tagsList } = useGetTagsQuery(currentSponsor.id);

	return (
		<StyledOptionsColumnBox disabled={disabled}>
			<FormGroup>
				<SelectAllOption
					selectedIds={selectedTagIds}
					setSelectedIds={setSelectedTagIds}
					availableIds={tagsList?.map((t) => t.id) || []}
					disabled={disabled}
				/>
				{tagsList &&
					tagsList.map((tag) => {
						return (
							<FormControlLabel
								key={tag.id}
								style={{ fontSize: '12px' }}
								control={
									<Checkbox
										size="small"
										onChange={() => {
											if (selectedTagIds.includes(tag.id)) {
												setSelectedTagIds(
													selectedTagIds.filter((tId) => tId !== tag.id),
												);
												return;
											}

											setSelectedTagIds([...selectedTagIds, tag.id]);
										}}
										checked={selectedTagIds.includes(tag.id) || false}
										disabled={disabled}
										style={{
											color: sponsorColor,
											padding: 3,
											marginLeft: 5,
										}}
									/>
								}
								label={<Typography>{tag.name}</Typography>}
							></FormControlLabel>
						);
					})}
			</FormGroup>
		</StyledOptionsColumnBox>
	);
};
