import React from 'react';
import { INonEditableUserRowProps } from '../types';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@mui/material';
import {
	Badge,
	Edit,
	Engineering,
	Mail,
	Person,
	Phone,
	Work,
} from '@mui/icons-material';
import { IconBtn } from 'common/components/IconBtn';
import { InputWrapper } from '../styles';

export const NonEditableUserRow: React.FC<INonEditableUserRowProps> = ({
	user,
	onEditclick,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<InputWrapper elevation={0}>
			<Grid container direction="row">
				<Grid item xs={11}>
					<List dense={true}>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'User Name'}>
									<Person sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.name}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'User Email'}>
									<Mail sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.email}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'Phone Number'}>
									<Phone sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.phone}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'Organization'}>
									<Work sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.organization}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'Title'}>
									<Badge sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.title}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
						<ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemIcon>
								<Tooltip title={'User Role'}>
									<Engineering sx={{ color: sponsorColor }} />
								</Tooltip>
							</ListItemIcon>
							<ListItemText
								primary={user.role}
								sx={{ marginRight: '1rem', wordWrap: 'break-word' }}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={1}>
					<Box display="flex" justifyContent="flex-end">
						<IconBtn onClick={onEditclick} tooltip={'Edit user information'}>
							<Edit sx={{ color: `${sponsorColor}` }} />
						</IconBtn>
					</Box>
				</Grid>
			</Grid>
		</InputWrapper>
	);
};
