import { FC } from 'react';
import { ExportModes, IExportButtonProps } from './types';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { BrandedButton } from 'common/components/BrandedButton';

const ExportButtonBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: '2rem',
	gap: '5px',
}));

export const ExportButton: FC<IExportButtonProps> = ({
	exportDisabled,
	onExportClick,
	mode,
}) => {
	const disabledMessage =
		mode === ExportModes.BY_USER ? 'fund and tag' : 'fund';

	return (
		<ExportButtonBox>
			<Tooltip
				title={
					exportDisabled
						? `Please select at least 1 ${disabledMessage} to start exporting`
						: ''
				}
			>
				<span>
					<BrandedButton
						disabled={exportDisabled}
						onClick={onExportClick}
						variant="outlined"
					>
						Export
					</BrandedButton>
				</span>
			</Tooltip>
		</ExportButtonBox>
	);
};
