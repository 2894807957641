import { SCOPES } from '../Scopes';

export const SponsorAdminPermissions: SCOPES[] = [
	/** Side Menu Scopes */
	SCOPES.canSeeOrganizationUsersOption,
	SCOPES.canSeeInvestorUsersOption,
	SCOPES.canSeeReportsMenuOption,
	SCOPES.canSeeAdminMenuOption,
	SCOPES.canSeeOrganizationTab,
	SCOPES.canSeeInvestorTab,

	/** Admin SideBar */
	SCOPES.canSeeBulkUploadOption,
	SCOPES.canSeeUploadDataOption,

	/** Fund scopes */
	SCOPES.canEditFund,
	SCOPES.canDownloadFund,
	SCOPES.canSearchFund,

	/** Investor scopes */
	SCOPES.canCreateInvestor,
	SCOPES.canDeleteInvestor,
	SCOPES.canDownloadInvestor,
	SCOPES.canEditInvestor,
	SCOPES.canSearchInvestor,
	SCOPES.canSeeInvestorsColumn,
	SCOPES.canSeeInvestorPermissionsColumn,
	SCOPES.canSeeInvestorLastLoginColumn,
	SCOPES.canSeeInvestorInfo,
	SCOPES.canEditInvestorInfo,
	SCOPES.canEditInvestorPermissions,
	SCOPES.canSeeInvestorNotes,
	SCOPES.canCreateInvestorNotes,
	SCOPES.canEditInvestorNotes,
	SCOPES.canDeleteInvestorNotes,
	SCOPES.canEditInvestorUserTags,

	/** Document scopes */
	SCOPES.canDeleteDocument,
	SCOPES.canNotifyInvestorUsersOnUploadedDocuments,
	SCOPES.canNotifySponsorUsersOnUploadedDocuments,
	SCOPES.canNotifyUsersOnUploadedData,
	SCOPES.canUploadDocument,
	SCOPES.canUpdateWaterMarkPdf,
	SCOPES.canDeleteCas,
	SCOPES.canNotifyOnDocuments,

	/** User scopes */
	SCOPES.canCreateUsers,
	SCOPES.canDeleteUsers,
	SCOPES.canSearchUsers,
	SCOPES.canDownloadUsers,
	SCOPES.canBulkUploadUsers,

	/** Sponsor scopes */
	SCOPES.canUploadLogo,
	SCOPES.canChangeHeader,
	SCOPES.canCreateAnnouncement,
	SCOPES.canEditDisclaimer,
	SCOPES.canEditAnnouncement,
	SCOPES.canDeleteAnnouncement,
	SCOPES.canSearchSponsor,
	SCOPES.canDownloadSponsor,

	/** Email Template scopes */
	SCOPES.canSeeEmailTemplates,
	SCOPES.canCreateEmailTemplates,
	SCOPES.canEditEmailTemplates,
	SCOPES.canDeleteEmailTemplates,
];
