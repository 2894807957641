import { Checkbox } from '@mui/material';
import {
	IPermissionsMutationResponse,
	useSaveInvestorUserTagsMutation,
} from 'api/redux/services/investors.service';
import { useLazyGetTagsQuery } from 'api/redux/services/tags.service';
import { UserInvestor } from 'app/types/user-investor.types';
import { hasPermission } from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from './ConfirmationModal';
import { SaveTabButton } from './SaveTabButton';
import { FormWrapper, InputWrapper, LabelBox, LabelWrapper } from './styles';
import { selectDropdown } from 'api/redux/DropdownReducer';

interface ITagsTabProps {
	userId: number;
	investorId: number;
	userInvestorPermissions: UserInvestor;
	setPermissions: Dispatch<SetStateAction<UserInvestor | undefined>>;
	changesMade: boolean;
	setChangesMade: Dispatch<SetStateAction<boolean>>;
	showConfirmation: boolean;
	handleClose: () => void;
	handleAfterSave: () => void;
	handleReload: () => void;
}

export const TagsTab: React.FC<ITagsTabProps> = ({
	investorId,
	userId,
	userInvestorPermissions,
	setPermissions,
	changesMade,
	setChangesMade,
	showConfirmation,
	handleClose,
	handleAfterSave,
	handleReload,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const grants = useSelector(selectDropdown);
	const currentSponsorId = grants.grants.currentSponsor.id;
	const [fetchTags, tags] = useLazyGetTagsQuery();
	const [saveUserInvestorTags, response] = useSaveInvestorUserTagsMutation();
	const [appliedTagIds, setAppliedTagIds] = useState<number[]>([]);

	useEffect(() => {
		if (currentSponsorId) {
			fetchTags(currentSponsorId);
		}
	}, [currentSponsorId, fetchTags]);

	useEffect(() => {
		setAppliedTagIds(userInvestorPermissions?.tags?.map((t) => t.id) || []);
	}, [tags.data, userInvestorPermissions]);

	const canEditInvestorUserTags = hasPermission({
		scopes: [SCOPES.canEditInvestorUserTags],
	});

	const handleSave = async () => {
		const newPermissions: IPermissionsMutationResponse =
			await saveUserInvestorTags({
				userId,
				investorId,
				tagIds: appliedTagIds.join(','),
			});

		if ('data' in newPermissions) {
			setPermissions(newPermissions.data);
		}

		handleAfterSave();
		handleReload();
	};

	const handleChange = (tagId: number) => {
		setChangesMade(true);
		if (appliedTagIds.includes(tagId)) {
			setAppliedTagIds(appliedTagIds.filter((id) => id !== tagId));
			return;
		}

		setAppliedTagIds([...appliedTagIds, tagId]);
	};

	return (
		<>
			<InputWrapper elevation={0}>
				<FormWrapper>
					{tags.data &&
						tags.data.map((tag, i) => {
							return (
								<LabelWrapper
									key={i}
									style={{ fontSize: '12px' }}
									control={
										<Checkbox
											size="small"
											disabled={!canEditInvestorUserTags}
											onChange={() => handleChange(tag.id)}
											checked={appliedTagIds.includes(tag.id) || false}
											style={{
												color: sponsorColor,
											}}
										/>
									}
									label={<LabelBox>{tag.name}</LabelBox>}
								></LabelWrapper>
							);
						})}
				</FormWrapper>

				<ConfirmationModal
					isOpen={showConfirmation}
					onAccept={handleSave}
					onCancel={handleClose}
					cancelText="Discard"
				/>
			</InputWrapper>

			<SaveTabButton
				changesMade={changesMade}
				handleSave={handleSave}
				saving={response.isLoading}
			/>
		</>
	);
};
