import { FC, useEffect, useState } from 'react';
import { DocumentTableKeys, DocumentTables, IButtonsRowProps } from './types';
import {
	downloadFile,
	fileName,
	specificDocumentUrl,
	urlForWaterMarkedDoc,
} from './utils';
import { Grid } from '@mui/material';
import { IconBtn } from 'common/components/IconBtn';
import { ContentCopy, GetApp, Print } from '@mui/icons-material';
import printJS from 'print-js-safari';
import { PopUpAlert } from '../../common/components/PopUpAlert';
import {
	UserInvestorPermissionOptions,
	UserInvestorPermissionsWrapper,
} from 'common/helpers/permissions/UserInvestorPermissionsWrapper';
import {
	useLazyGetOrganizationQuery,
	useLazyGetWatermarkDocTypesQuery,
} from 'api/redux/services/organizations.service';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { DocumentType } from 'app/types/documentTypes.types';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import { documentsState } from 'api/redux/DocumentsReducer';

export const ButtonsRow: FC<IButtonsRowProps> = ({ docUrl }) => {
	const { user } = useGrants();
	const { currentDocument: document } = useSelector(documentsState);
	const grants = useSelector(selectDropdown);
	const { currentSponsor, currentInvestor } = grants.grants;
	const [fetchWatermarkDocTypes, watermarkDocTypes] =
		useLazyGetWatermarkDocTypesQuery();
	const [allowedWatermarkDocTypes, setAllowedWatermarkDocTypes] = useState<
		DocumentType[]
	>([]);
	const [fetchOrganization, sponsor] = useLazyGetOrganizationQuery();
	const [showDocUrlCopied, setShowDocUrlCopied] = useState<boolean>(false);
	const allowedDocTypeForWatermark =
		document !== undefined &&
		allowedWatermarkDocTypes
			.map((dt) => dt.id)
			.includes(document.documentTypeId);

	const tableKey =
		(document?.table as DocumentTables) === DocumentTables.FUND_DOCUMENTS
			? DocumentTableKeys.FT
			: DocumentTableKeys.IT;
	const handlePrint = async () => {
		if (!sponsor.data) return;

		if (!sponsor.data.watermarkPdf || !allowedDocTypeForWatermark) {
			printJS({ printable: docUrl });
			return;
		}

		const fileUrl: string = await urlForWaterMarkedDoc(docUrl, user);

		printJS({ printable: fileUrl });
	};

	const handleDownload = async () => {
		if (!sponsor.data || !document) return;

		if (!sponsor.data?.watermarkPdf || !allowedDocTypeForWatermark) {
			downloadFile({
				fileUrl: docUrl,
				fileName: fileName(document),
			});
			return;
		}

		const fileUrl: string = await urlForWaterMarkedDoc(docUrl, user);

		downloadFile({ fileUrl, fileName: fileName(document) });
	};

	const onCopyClick = () => {
		if (!document) return;

		navigator.clipboard.writeText(
			specificDocumentUrl({
				document,
				withBase: true,
				tableKey: tableKey as DocumentTableKeys,
				investorId: currentInvestor.id,
			}),
		);
	};

	useEffect(() => {
		fetchOrganization(currentSponsor.id);
		fetchWatermarkDocTypes(currentSponsor.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!watermarkDocTypes.data) return;

		setAllowedWatermarkDocTypes(watermarkDocTypes.data);
	}, [watermarkDocTypes.data]);

	return (
		<>
			<PopUpAlert
				show={showDocUrlCopied}
				onClose={() => setShowDocUrlCopied(false)}
				severity="success"
				message="Document URL copied!"
			/>
			<Grid container padding={0} sx={{ alignItems: 'flex-end' }}>
				<Grid item xs={4}>
					<IconBtn
						onClick={() => {
							onCopyClick();
							setShowDocUrlCopied(true);
						}}
						customColor={true}
						tooltip="Copy URL"
					>
						<ContentCopy />
					</IconBtn>
				</Grid>
				<Grid item xs={4} textAlign={'center'}>
					<UserInvestorPermissionsWrapper
						ability={UserInvestorPermissionOptions.canDownload}
					>
						<IconBtn
							onClick={handleDownload}
							customColor={true}
							tooltip="Download"
						>
							<GetApp />
						</IconBtn>
					</UserInvestorPermissionsWrapper>
				</Grid>{' '}
				<Grid item xs={4} textAlign={'end'}>
					<UserInvestorPermissionsWrapper
						ability={UserInvestorPermissionOptions.canPrint}
					>
						<IconBtn onClick={handlePrint} customColor={true} tooltip="Print">
							<Print />
						</IconBtn>
					</UserInvestorPermissionsWrapper>
				</Grid>
			</Grid>
		</>
	);
};
