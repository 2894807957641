import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ExportModes, IExportProps } from './types';
import {
	useLazyGetOrganizationUsersByInvestorCsvQuery,
	useLazyGetOrganizationUsersByUserCsvQuery,
} from 'api/redux/services/organizations.service';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import fileDownload from 'js-file-download';
import { Box, Grid } from '@mui/material';
import { FundsList } from './FundsList';
import { ExportButton } from './ExportButton';
import { TagsList } from './TagsList';

export const Export: FC<IExportProps> = ({ mode }) => {
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor } = grants;
	const [selectedFundIds, setSelectedFundIds] = useState<number[]>([]);
	const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
	const [filterLabel, setFilterLabel] = useState<string>(
		mode === ExportModes.BY_USER ? 'User' : 'Investor',
	);
	const [downloadUsersByInvestorCSV, investorsFile] =
		useLazyGetOrganizationUsersByInvestorCsvQuery();
	const [downloadUsersByUserCSV, usersFile] =
		useLazyGetOrganizationUsersByUserCsvQuery();
	const exportDisabled =
		!selectedFundIds.length ||
		(mode === ExportModes.BY_USER && !selectedTagIds.length);

	useEffect(() => {
		if (!usersFile.data || usersFile.isFetching) return;

		fileDownload(
			usersFile.data,
			`${currentSponsor.name || currentSponsor.id}.users.export.csv`,
		);
	}, [usersFile]);

	useEffect(() => {
		if (!investorsFile.data || investorsFile.isFetching) return;

		fileDownload(
			investorsFile.data,
			`${currentSponsor.name || currentSponsor.id}.investors.export.csv`,
		);
	}, [investorsFile]);

	useEffect(() => {
		setFilterLabel(mode === ExportModes.BY_USER ? 'User' : 'Investor');
	}, [mode]);

	const handleExport = () => {
		if (mode === ExportModes.BY_USER) {
			downloadUsersByUserCSV({
				sponsorId: currentSponsor.id,
				fundIds: selectedFundIds,
				tagIds: selectedTagIds,
			});
			return;
		}

		downloadUsersByInvestorCSV({
			sponsorId: currentSponsor.id,
			fundIds: selectedFundIds,
		});
	};

	return (
		<Box sx={{ padding: '1rem' }}>
			<Typography align="center" variant={'h3'} marginBottom={5}>
				{filterLabel} Filters
			</Typography>
			<Grid container direction={'row'} justifyContent={'space-around'}>
				<Grid item sm={4} md={4}>
					<Typography align="center" variant={'h4'}>
						Select Funds
					</Typography>
					<FundsList
						selectedFundIds={selectedFundIds}
						setSelectedFundIds={setSelectedFundIds}
					/>
				</Grid>
				<Grid item sm={4} md={4}>
					<Typography
						align="center"
						variant={'h4'}
						style={{ opacity: mode === ExportModes.BY_INVESTOR ? 0.5 : 1 }}
					>
						Select Tags
					</Typography>
					<TagsList
						selectedTagIds={selectedTagIds}
						setSelectedTagIds={setSelectedTagIds}
						disabled={mode === ExportModes.BY_INVESTOR}
					/>
				</Grid>
			</Grid>
			<ExportButton
				exportDisabled={exportDisabled}
				onExportClick={handleExport}
				mode={mode}
			/>
		</Box>
	);
};
