import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DROPDOWNS_TOTAL_LENGTH } from './types';
import { AppBar, Box, Toolbar } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
	onNavbarMobile,
	selectScreenWidth,
	onScreenWidthChange,
} from 'api/redux/NavBarStateReducer';
import { StandardMenu } from './StandardMenu';
import { useCookies } from 'react-cookie';
import { SponsorLogo } from './SponsorLogo';
import { MenuButton } from './MenuButton';
import { DropDowns } from './dropdowns/DropDowns';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { getCookie } from 'common/helpers/Cookies';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';
import { styled } from '@mui/system';

const AppBarBox = styled(AppBar)((theme) => ({
	color: '#121212',
	shadows: 'none',
	width: '100%',
	zIndex: 1201,
}));

const ToolBarBox = styled(Box)(() => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	minHeight: '60px',
}));

const DropdownBox = styled(Box)(() => ({
	height: '100%',
	width: 'max-content',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	alignItems: 'center',
}));

const MenuBox = styled(Box)(() => ({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
}));

export const NavbarElements: FC = () => {
	const dispatch = useDispatch();
	const { logout } = useAuth0();
	const screenWidth = useSelector(selectScreenWidth);
	const isMobile = screenWidth < DROPDOWNS_TOTAL_LENGTH;
	const [anchorEl, setAnchorEl] = useState(null);
	const isMenuOpen = Boolean(anchorEl);
	const { currentUser } = useLocalUser();
	const [, , removeCookies] = useCookies([
		'fundId',
		'sponsorId',
		'investorId',
		'subdomain',
	]);
	const blurStyle = !currentUser?.initialLogin ? { filter: 'blur(5px)' } : {};
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	useEffect(() => {
		const handleWindowResize = () => {
			dispatch(onScreenWidthChange(window.innerWidth));
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	});

	const handleMenuOpen = (event: React.BaseSyntheticEvent) => {
		setAnchorEl(event.currentTarget);
	};

	const handleLogOut = () => {
		const url = new URL(window.location.origin);
		const subdomain = getCookie('subdomain');
		if (subdomain) {
			url.searchParams.append('subdomain', subdomain);
		}

		logout({ logoutParams: { returnTo: url.toString() } });
		removeCookies('fundId', { path: '/' });
		removeCookies('sponsorId', { path: '/' });
		removeCookies('investorId', { path: '/' });
		removeCookies('subdomain', { path: '/' });
	};

	useEffect(() => {
		dispatch(onNavbarMobile(isMobile));
	}, [isMobile, dispatch]);

	return (
		<>
			<AppBarBox
				style={{
					...blurStyle,
					backgroundColor: sponsorColor,
				}}
				elevation={0}
				position="fixed"
			>
				<Toolbar
					disableGutters={true}
					style={{ width: '100%', height: '100%' }}
				>
					<ToolBarBox>
						<SponsorLogo />

						<DropdownBox>
							<DropDowns />

							<MenuBox>
								<MenuButton
									windowWidth={screenWidth}
									handleMenuOpen={handleMenuOpen}
								/>
							</MenuBox>
						</DropdownBox>
					</ToolBarBox>
				</Toolbar>
			</AppBarBox>

			<StandardMenu
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				isOpen={isMenuOpen}
				logout={handleLogOut}
			/>
		</>
	);
};
