import { createApi } from '@reduxjs/toolkit/query/react';
import dynamicBaseQuery from 'app/dynamic-base-query';
import { Document } from 'app/types/document.types';
import { DocumentType } from 'app/types/documentTypes.types';
import { ISortStrategy } from '../types';

export interface INotifySponsorUserParams {
	fundId: number;
	numberOfDocs: number;
	documentType: string;
	documentPeriod: string;
	documentIds: number[];
	notificationDelayId: number;
	documentLevelId: number;
}

export interface IPaginatedDocumentsResponse {
	pages: number;
	data: IDocumentResponse[];
}

export interface IDocumentResponse {
	id: number;
	investorName: string;
	fundName: string;
	table: string;
	period: string;
	type: string;
	documentTypeId: number;
	gcloudPath: string;
	fundId: number;
	organizationId: number;
	investorId: string;
}

interface IGetDocumentsParams {
	fundId: number;
	investorId: number;
	page: number;
	documentTypeIds: number[];
	sortStrategy: ISortStrategy[];
}

interface IGetAvailableDocttypesParams {
	fundId: number;
	investorId: number;
}

interface IUploadDocumentParams {
	formData: FormData;
}

export enum EManualNotificationType {
	CONFIRM_RECEIPT = 'confirm-receipt',
	REMINDER = 'reminder',
}

interface IManuallyNotifyParams {
	documentId: number;
	documentLevelId: number;
	notificationType: EManualNotificationType;
}

export const documentApi = createApi({
	reducerPath: 'documentsApi',
	baseQuery: dynamicBaseQuery('documents'),
	endpoints: (builder) => ({
		getInvestorDocument: builder.query<Document, number>({
			query: (documentId: number) => ({
				url: `${documentId}/investor-level`,
			}),
		}),
		getFundDocument: builder.query<Document, number>({
			query: (documentId: number) => ({
				url: `${documentId}/fund-level`,
			}),
		}),
		getInvestorDocumentPdf: builder.query<Blob, number>({
			query: (documentId: number) => ({
				url: `${documentId}/investor-level/content`,
				responseHandler: async (response) => await response.blob(),
			}),
		}),
		getFundDocumentPdf: builder.query<Blob, number>({
			query: (documentId: number) => ({
				url: `${documentId}/fund-level/content`,
				responseHandler: async (response) => await response.blob(),
			}),
		}),
		getDocuments: builder.query<
			IPaginatedDocumentsResponse,
			IGetDocumentsParams
		>({
			query: ({ investorId, fundId, page, documentTypeIds, sortStrategy }) => ({
				url: `?fundId=${fundId}&investorId=${investorId}&page=${page}&documentTypeIds=${documentTypeIds.join(
					',',
				)}&sortBy=${sortStrategy.map(
					(ss: ISortStrategy) => `${ss.column}:${ss.ascdesc}`,
				)}`,
			}),
		}),
		getAvailableDocumentsType: builder.query<
			DocumentType[],
			IGetAvailableDocttypesParams
		>({
			query: ({ investorId, fundId }) => ({
				url: `types?fundId=${fundId}&investorId=${investorId}`,
			}),
		}),
		uploadDocument: builder.mutation<Document, IUploadDocumentParams>({
			query: ({ formData }) => ({
				url: `document`,
				method: 'POST',
				body: formData,
			}),
		}),
		uploadFundDocument: builder.mutation<Document[], IUploadDocumentParams>({
			query: ({ formData }) => ({
				url: `fund`,
				method: 'POST',
				body: formData,
			}),
		}),
		deleteDocument: builder.mutation({
			query: ({ documentId, documentTable }) => ({
				url: `?documentId=${documentId}&documentTable=${documentTable}`,
				method: 'DELETE',
			}),
		}),
		updateDocument: builder.mutation({
			query: (documentId) => ({ url: `${documentId}` }),
		}),
		notifySponsorLevelUsers: builder.mutation<void, INotifySponsorUserParams>({
			query: ({
				fundId,
				numberOfDocs,
				documentType,
				documentPeriod,
				documentIds,
				notificationDelayId,
				documentLevelId,
			}) => ({
				url: `notify-sponsor-level-users?fund-id=${fundId}
					&number-of-docs=${numberOfDocs}
					&document-type=${documentType}
					&document-period=${documentPeriod}
					&document-ids=${documentIds.join(',')}
					&notification-delay-id=${notificationDelayId}
					&document-level-id=${documentLevelId}`,
				method: 'POST',
			}),
		}),
		manuallyNotify: builder.mutation<void, IManuallyNotifyParams>({
			query: ({ documentId, documentLevelId, notificationType }) => ({
				url: `${documentId}/notify`,
				body: {
					documentLevelId,
					notificationType,
				},
				method: 'POST',
			}),
		}),
	}),
});

export const {
	useUploadDocumentMutation,
	useUploadFundDocumentMutation,
	useLazyGetInvestorDocumentQuery,
	useLazyGetFundDocumentQuery,
	useLazyGetDocumentsQuery,
	useDeleteDocumentMutation,
	useNotifySponsorLevelUsersMutation,
	useLazyGetAvailableDocumentsTypeQuery,
	useLazyGetFundDocumentPdfQuery,
	useLazyGetInvestorDocumentPdfQuery,
	useManuallyNotifyMutation,
} = documentApi;
