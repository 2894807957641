import { Action, ThunkAction, configureStore, Store } from '@reduxjs/toolkit';
import { documentApi } from './services/documentApi';
import { configApi } from './services/config';
import { organizationsApi } from './services/organizations.service';
import { userApi } from './services/userApi';
import { capitalActivityApi } from './services/capitalActivityApi';
import { announcementsApi } from './services/announcementsApi';
import grants from './DropdownReducer';
import docs from './DocumentsReducer';
import docsUpload from './DocsUploadReducer';
import users from './UsersReducer';
import siteColor from './siteColorReducer';
import redirectURL from './RedirectURLReducer';
import navbarState from './NavBarStateReducer';
import { helpApi } from './services/helpApi';
import { investorsApi } from './services/investors.service';
import { reportTypesApi } from './services/reportTypes.service';
import { documentTypesApi } from './services/documentTypes.service';
import { addressesApi } from './services/address.service';
import { fundApi } from './services/fundApi';
import { authApi } from './services/auth.service';
import { investorNotesApi } from './services/investors.notes.service';
import { featureFlagsApi } from './services/featureFlags.service';
import { emailTemplatesApi } from './services/email.templates.service';
import { tagsApi } from './services/tags.service';

export const store: Store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		[addressesApi.reducerPath]: addressesApi.reducer,
		[documentApi.reducerPath]: documentApi.reducer,
		[configApi.reducerPath]: configApi.reducer,
		[investorsApi.reducerPath]: investorsApi.reducer,
		[organizationsApi.reducerPath]: organizationsApi.reducer,
		[reportTypesApi.reducerPath]: reportTypesApi.reducer,
		[documentTypesApi.reducerPath]: documentTypesApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[capitalActivityApi.reducerPath]: capitalActivityApi.reducer,
		[announcementsApi.reducerPath]: announcementsApi.reducer,
		[investorNotesApi.reducerPath]: investorNotesApi.reducer,
		[helpApi.reducerPath]: helpApi.reducer,
		[fundApi.reducerPath]: fundApi.reducer,
		[featureFlagsApi.reducerPath]: featureFlagsApi.reducer,
		[emailTemplatesApi.reducerPath]: emailTemplatesApi.reducer,
		[tagsApi.reducerPath]: tagsApi.reducer,
		grants,
		docs,
		docsUpload,
		users,
		siteColor,
		navbarState,
		redirectURL,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			authApi.middleware,
			addressesApi.middleware,
			documentApi.middleware,
			configApi.middleware,
			investorsApi.middleware,
			organizationsApi.middleware,
			reportTypesApi.middleware,
			documentTypesApi.middleware,
			fundApi.middleware,
			userApi.middleware,
			capitalActivityApi.middleware,
			announcementsApi.middleware,
			investorNotesApi.middleware,
			helpApi.middleware,
			featureFlagsApi.middleware,
			emailTemplatesApi.middleware,
			tagsApi.middleware,
		),
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
