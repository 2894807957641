export enum SCOPES {
	/** Side Menu Scopes */
	canSeeOrganizationUsersOption = 'can-see-organization-users-option',
	canSeeInvestorUsersOption = 'can-see-investor-users-option',
	canSeeReportsMenuOption = 'can-see-reports-menu-option',
	canSeeAdminMenuOption = 'can-see-admin-menu-option',
	canSeeOrganizationTab = 'can-see-organization-tab',
	canSeeInvestorTab = 'can-see-investor-tab',

	/** Admin SideBar */
	canSeeBulkUploadOption = 'can-see-bulk-upload-option',
	canSeeUploadDataOption = 'can-see-upload-data-option',

	/** Fund scopes */
	canCreateFund = 'can-create-fund',
	canDeleteFund = 'can-delete-fund',
	canEditFund = 'can-edit-fund',
	canDownloadFund = 'can-download-fund',
	canSearchFund = 'can-search-fund',

	/** Investor scopes */
	canCreateInvestor = 'can-create-investor',
	canDeleteInvestor = 'can-delete-investor',
	canEditInvestor = 'can-edit-investor',
	canDownloadInvestor = 'can-download-investor',
	canSearchInvestor = 'can-search-investor',
	canSeeInvestorsColumn = 'can-see-investors-column',
	canSeeInvestorPermissionsColumn = 'can-see-investor-permissions-column',
	canSeeInvestorLastLoginColumn = 'can-see-investor-last-login-column',
	canSeeInvestorInfo = 'can-see-investor-info',
	canEditInvestorInfo = 'can-edit-investor-info',
	canEditInvestorPermissions = 'can-edit-investor-permissions',
	canSeeInvestorNotes = 'can-see-investor-notes',
	canCreateInvestorNotes = 'can-create-investor-notes',
	canEditInvestorNotes = 'can-edit-investor-notes',
	canDeleteInvestorNotes = 'can-delete-investor-notes',
	canEditInvestorUserTags = 'can-edit-investor-user-tags',

	/** Document scopes */
	canDeleteDocument = 'can-delete-document',
	canNotifyInvestorUsersOnUploadedDocuments = 'notify-investor-users-on-uploaded-documents',
	canNotifySponsorUsersOnUploadedDocuments = 'notify-sponsor-users-on-uploaded-documents',
	canNotifyUsersOnUploadedData = 'notify-users-on-uploaded-data',
	canUploadDocument = 'can-upload-document',
	canUpdateWaterMarkPdf = 'can-update-watermark-pdf',
	canDeleteCas = 'can-delete-cas',
	canNotifyOnDocuments = 'can-notify-on-documents',

	/** User scopes */
	canCreateUsers = 'can-add-users',
	canDeleteUsers = 'can-delete-users',
	canSearchUsers = 'can-search-users',
	canDownloadUsers = 'can-donwload-users',
	canBulkUploadUsers = 'can-bulk-upload-users',

	/** Sponsor scopes */
	canCreateSponsor = 'can-create-sponsor',
	canEditSponsor = 'can-edit-sponsor',
	canDeleteSponsor = 'can-delete-sponsor',
	canSearchSponsor = 'can-search-sponsor',
	canDownloadSponsor = 'can-download-sponsor',
	canUploadLogo = 'can-upload-logo',
	canChangeHeader = 'can-change-header',
	canCreateAnnouncement = 'can-create-announcement',
	canEditDisclaimer = 'can-edit-disclaimer',
	canEditAnnouncement = 'can-edit-announcement',
	canDeleteAnnouncement = 'can-delete-announcement',

	/** Email Template scopes */
	canSeeEmailTemplates = 'can-see-email-templates',
	canCreateEmailTemplates = 'can-create-email-templates',
	canEditEmailTemplates = 'can-edit-email-templates',
	canDeleteEmailTemplates = 'can-delete-email-templates',
}
