import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { IContributionProps, IGraphData } from './DashboardTypes';
import { currencyFormatter } from 'common/helpers/Math';
import ContributionsPieChart from './ContributionsPieChart';
import { ECASTemplates } from './types';
import {
	FallbackKeys,
	RowNames,
} from 'modules/reports/CapitalAccountStatement/CASTable/types';
import { styled } from '@mui/system';

const SummaryHeading = styled(Typography)(({ theme }) => ({
	fontSize: 12,
}));

const SummaryValue = styled(Typography)(({ theme }) => ({
	fontSize: 12,
}));

const GraphBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '80%',
	padding: 20,
	fontFamily: 'lato !important',
}));

const NoDataText = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
}));

export const Contributions: FC<IContributionProps> = ({
	capitalActivityData,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentFund, currentSponsor } = grants.grants;
	const [netContributions, setNetContributions] = useState<number>(0);
	const [netCommitments, setNetCommitments] = useState<number>(0);
	const [contributionPercentage, setContributionPercentage] =
		useState<number>(0);
	const [fullyFunded, setFullyFunded] = useState<boolean>(true);
	const [graphData, setGraphData] = useState<Array<IGraphData>>();

	const invalidGraphState =
		currentInvestor.id == 0 ||
		currentFund.id == 0 ||
		currentSponsor.id == 0 ||
		!capitalActivityData ||
		capitalActivityData.length === 0;

	const deconfigureGraph = () => {
		setGraphData(undefined);
		setNetCommitments(0);
		setNetContributions(0);
		setContributionPercentage(0);
	};

	const readContributions = () => {
		if (!capitalActivityData[0].capitalActivityTemplate.active) {
			deprecatedReadContributions();
			return;
		}

		activeReadContributions();
	};

	const activeReadContributions = () => {
		const filteredData = capitalActivityData[0].attributeData.filter(
			(d) =>
				[
					...FallbackKeys.TOTAL_CONTRIBUTIONS,
					...FallbackKeys.CONTRIBUTIONS_RECEIVABLE,
					RowNames.RECALLABLE_DISTRIBUTIONS,
				].includes(d.name as RowNames) && d.value !== '',
		);

		const contributions = filteredData
			.map((entry) => parseInt(entry.value))
			.reduce((prev, next) => prev + next, 0);

		setNetContributions(Math.abs(contributions));
	};

	const deprecatedReadContributions = () => {
		const currentTemplate: ECASTemplates = capitalActivityData[0]
			.capitalActivityTemplate.templateId as ECASTemplates;
		const contributions = capitalActivityData[0].attributeData.filter(
			(attribute) =>
				FallbackKeys.CONTRIBUTIONS_ITD.includes(attribute.name) &&
				attribute.value != '',
		);

		if (contributions.length === 0) {
			setNetContributions(0);
			return;
		}

		const partialNetContributions: number = contributions
			.map((entry) => parseInt(entry.value))
			.reduce((prev, next) => prev + next);

		if (
			[
				ECASTemplates.INCEPTION_WITH_CONTRIBUTIONS_RECEIVABLE,
				ECASTemplates.NO_INCEPTION_WITH_CONTRIBUTIONS_RECEIVABLE,
			].includes(currentTemplate)
		) {
			const contributionsReceivable = capitalActivityData[0].attributeData.find(
				(attribute) =>
					FallbackKeys.CONTRIBUTIONS_RECEIVABLE.includes(attribute.name) &&
					attribute.value != '',
			);

			setNetContributions(
				partialNetContributions -
					parseInt(contributionsReceivable?.value || '0'),
			);
			return;
		}

		setNetContributions(partialNetContributions);
	};

	const readCommitments = () => {
		const commitment = capitalActivityData[0].attributeData.filter(
			(attribute) =>
				FallbackKeys.REMAINING_COMMITMENT.includes(attribute.name) &&
				attribute.value != '',
		);

		if (commitment.length === 0) {
			setNetCommitments(0);
			return;
		}

		setNetCommitments(
			commitment
				.map((entry) => parseInt(entry.value))
				.reduce((prev, next) => prev + next),
		);
	};

	const configureGraph = () => {
		setFullyFunded(netCommitments === 0);
		setContributionPercentage(
			netCommitments / (netCommitments + netContributions),
		);
		setGraphData([
			{
				id: 'Remaining Commitment',
				value: netCommitments,
			},
			{
				id: 'Total Contributed',
				value: netContributions,
			},
		]);
	};

	useEffect(() => {
		if (invalidGraphState) {
			deconfigureGraph();
			return;
		}

		readContributions();
		readCommitments();
		configureGraph();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentSponsor,
		currentInvestor,
		currentFund,
		capitalActivityData,
		netContributions,
		netCommitments,
	]);

	const GraphHeader: FC = () => {
		return (
			<Box sx={{ textAlign: 'center', display: 'flex', marginTop: '24px' }}>
				<Box
					sx={{
						marginLeft: 'auto',
						marginRight: 'auto',
						textAlign: 'center',
					}}
				>
					<SummaryHeading>Total Contributed</SummaryHeading>
					{graphData ? (
						<SummaryValue sx={{ color: `${sponsorColor}` }}>
							{netContributions !== 0
								? currencyFormatter.format(Math.abs(netContributions))
								: '-'}
						</SummaryValue>
					) : (
						<SummaryValue>-</SummaryValue>
					)}
				</Box>
				<Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
					<SummaryHeading>Remaining Commitment</SummaryHeading>
					<SummaryValue sx={{ color: `${sponsorColor}` }}>
						{!fullyFunded && netCommitments !== 0
							? currencyFormatter.format(netCommitments)
							: '-'}
					</SummaryValue>
				</Box>
				<Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
					<SummaryHeading>Capital Commitment</SummaryHeading>
					<SummaryValue sx={{ color: `${sponsorColor}` }}>
						{Math.abs(netContributions) + netCommitments != 0
							? currencyFormatter.format(
									Math.abs(netContributions) + netCommitments,
							  )
							: '-'}
					</SummaryValue>
				</Box>
			</Box>
		);
	};

	const GraphBody: FC = () => {
		return (
			<GraphBox>
				{graphData && (
					<ContributionsPieChart
						graphData={graphData}
						contributionPercentage={contributionPercentage}
						sponsorColor={sponsorColor}
						fullyFunded={fullyFunded}
						totalCommitment={netContributions + netCommitments}
					/>
				)}

				{!graphData && <NoDataText>-</NoDataText>}
			</GraphBox>
		);
	};

	return (
		<Box sx={{ height: '100%' }}>
			<Typography variant="h4">Contributions</Typography>
			<GraphHeader />
			<GraphBody />
		</Box>
	);
};

export default Contributions;
