import { selectDropdown } from 'api/redux/DropdownReducer';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetInvestorPermissionsQuery } from 'api/redux/services/investors.service';
import { FC, ReactElement, memo } from 'react';
import { useSelector } from 'react-redux';
import { useGrants } from './use-grants/useGrants';

interface IUserInvestorPermissionsWrapperProps {
	children: ReactElement;
	ability: UserInvestorPermissionOptions;
}

export enum UserInvestorPermissionOptions {
	canPrint = 'canPrint',
	canDownload = 'canDownload',
}

export const UserInvestorPermissionsWrapper: FC<IUserInvestorPermissionsWrapperProps> =
	memo(
		({ ability, children }) => {
			const grants = useSelector(selectDropdown);
			const { currentInvestor } = grants.grants;
			const { isAdmin } = useGrants();

			const { data: permissions } = useGetInvestorPermissionsQuery(
				// Only make the query if we need to
				!isAdmin && currentInvestor.id !== 0
					? { investorId: currentInvestor.id }
					: skipToken,
			);

			const hasAbility = isAdmin || (permissions && permissions[ability]);

			if (!hasAbility) return null;

			return children;
		},
		(prevProps, nextProps) =>
			prevProps.ability === nextProps.ability &&
			prevProps.children === nextProps.children,
	);
