import { createApi } from '@reduxjs/toolkit/query/react';
import dynamicBaseQuery from 'app/dynamic-base-query';
import {
	CreateEmailTemplate,
	EmailTemplate,
	ISendPreviewEmailProps,
	UpdateEmailTemplate,
} from 'app/types/email.template.types';

export interface IGetEmailTemplatesParams {
	sponsorId: number;
	hideManualNotificationTemplates: boolean;
}

export const emailTemplatesApi = createApi({
	reducerPath: 'emailTemplatesApi',
	baseQuery: dynamicBaseQuery('email-templates'),
	tagTypes: ['EmailTemplate'],
	endpoints: (builder) => ({
		getEmailTemplates: builder.query<EmailTemplate[], IGetEmailTemplatesParams>(
			{
				query: ({ sponsorId, hideManualNotificationTemplates }) =>
					`?sponsorId=${sponsorId}&hideManualNotificationTemplates=${hideManualNotificationTemplates}`,
			},
		),
		getEmailTemplateById: builder.query({
			query: (id) => `${id}`,
		}),
		createEmailTemplate: builder.mutation<EmailTemplate, CreateEmailTemplate>({
			query: (newEmailTemplate) => ({
				url: '',
				method: 'POST',
				body: newEmailTemplate,
			}),
		}),
		updateEmailTemplate: builder.mutation<EmailTemplate, UpdateEmailTemplate>({
			query: (updatedEmailTemplate) => ({
				url: `${updatedEmailTemplate.id}`,
				method: 'PUT',
				body: updatedEmailTemplate,
			}),
		}),
		deleteEmailTemplate: builder.mutation({
			query: (id) => ({
				url: `${id}`,
				method: 'DELETE',
			}),
		}),
		sendPreviewEmail: builder.mutation<void, ISendPreviewEmailProps>({
			query: (sendPreviewEmailProps) => ({
				url: `${sendPreviewEmailProps.emailTemplateId}/send-preview-email`,
				method: 'POST',
				body: sendPreviewEmailProps,
			}),
		}),
	}),
});

export const {
	useGetEmailTemplatesQuery,
	useLazyGetEmailTemplateByIdQuery,
	useCreateEmailTemplateMutation,
	useUpdateEmailTemplateMutation,
	useDeleteEmailTemplateMutation,
	useSendPreviewEmailMutation,
} = emailTemplatesApi;
