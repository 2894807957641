import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { saveToken } from './utils';

export const RefreshTokenWrapper: React.FC = () => {
	const { getAccessTokenSilently } = useAuth0();
	/**
	 * We make a reset token request every 1m 55s.
	 * This is due to the SSO (Auth0) is configured to expire the token every 2 mins for security reasons,
	 * and in order to avoid the use of expired tokens due to possible delay in network request times.
	 */
	const twoMins = 115000;

	const refreshToken = async () => {
		try {
			const token = await getAccessTokenSilently();
			saveToken(token);
		} catch (error) {
			console.error('Failed to refresh token:', error);
		}
	};

	useEffect(() => {
		refreshToken();

		const intervalId = setInterval(refreshToken, twoMins);

		return () => clearInterval(intervalId);
	}, []);

	return <></>;
};

export default RefreshTokenWrapper;
