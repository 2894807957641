import { FC, useEffect, useState } from 'react';
import { privacyPolicyText } from './privacyPolicyText';
import { termsOfUseText } from './termsOfUseText';
import { useUpdateLoginStatusMutation } from 'api/redux/services/userApi';
import { Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { redirectURLState } from 'api/redux/RedirectURLReducer';
import { Navigate } from 'react-router-dom';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { Alert, Box, Dialog, DialogActions } from '@mui/material';
import { styled } from '@mui/system';
import { BrandedButton } from 'common/components/BrandedButton';

const LegalDisclaimerBox = styled(Box)(() => ({
	overflow: 'hidden',
	margin: 20,
	height: '25rem',
}));

const TextBox = styled(Box)(() => ({
	overflow: 'auto',
	height: '300px',
}));

export const PrivacyPolicy: FC = () => {
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined,
	);
	const [
		setLogintoTrue,
		{ isSuccess: termsAccepted, isError, isLoading, error },
	] = useUpdateLoginStatusMutation();
	const { logout, getAccessTokenSilently } = useAuth0();
	const { currentUser, refetch } = useLocalUser();
	const redirectURL = useSelector(redirectURLState);
	const handleSubmitPrivacyPolicy = async () => {
		await setLogintoTrue();

		getAccessTokenSilently({ cacheMode: 'off' });
	};

	useEffect(() => {
		if (termsAccepted) {
			setErrorMessage(undefined);
			refetch();
		}
	}, [termsAccepted]);

	useEffect(() => {
		if (isError) setErrorMessage('An error has occurred');
	}, [isError]);

	if (redirectURL && currentUser?.initialLogin) {
		const url = redirectURL;
		return <Navigate to={url} />;
	}

	if (isError) {
		return (
			<Alert severity="error" variant="filled">
				{errorMessage}
			</Alert>
		);
	}

	return (
		<Dialog
			open={currentUser !== undefined && !currentUser.initialLogin}
			fullWidth
			maxWidth="sm"
		>
			<LegalDisclaimerBox>
				{isLoading ? (
					<LoadingSpinner />
				) : (
					<>
						<Typography variant="h5" style={{ marginBottom: '10px' }}>
							LPx Privacy Policy and Terms of Use
						</Typography>
						<TextBox>
							<Typography style={{ whiteSpace: 'pre-wrap' }}>
								{privacyPolicyText}
								{termsOfUseText}
							</Typography>
						</TextBox>
						<DialogActions>
							<BrandedButton
								onClick={() =>
									logout({ logoutParams: { returnTo: window.location.origin } })
								}
							>
								I Disagree
							</BrandedButton>
							<BrandedButton
								variant="contained"
								onClick={handleSubmitPrivacyPolicy}
							>
								I Agree
							</BrandedButton>
						</DialogActions>
					</>
				)}
			</LegalDisclaimerBox>
		</Dialog>
	);
};
