import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { ExportModes } from './types';
import { ExportMode } from './ExportMode';
import { Export } from './Export';

export const ExportUsersCSV: FC = () => {
	const [mode, setMode] = useState<ExportModes>(ExportModes.BY_USER);

	const handleModeChange = (mode: ExportModes) => {
		setMode(mode);
	};

	return (
		<Box>
			<ExportMode value={mode} handleChange={handleModeChange} />

			<Export mode={mode} />
		</Box>
	);
};
