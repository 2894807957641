import { selectDropdown } from 'api/redux/DropdownReducer';
import { Container, Grid, Paper, GridSize } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	ITotalCommitmentReport,
	useLazyGetInvestorQuery,
	useLazyGetInvestorTotalCommitmentQuery,
} from 'api/redux/services/investors.service';
import { CapitalActivityData } from './CapitalActivityData';
import { InvestorMetaData } from './InvestorMetadata';
import { EditInvestorModal } from './EditInvestorModal';
import { Investor } from 'app/types/investor.types';
import numberFormat from 'common/helpers/Math';
import { ISingleCommitment } from './types';
import { InvestorNotes } from './InvestorNotes';
import PermissionsWrapper, {
	hasPermission,
} from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { styled } from '@mui/system';

const RootContainer = styled(Grid)(() => ({
	display: 'flex',
	paddingRight: 0,
	paddingLeft: 0,
	marginRight: 0,
	marginTop: 0,
	overflow: 'hidden',
	width: '100%',
}));

const StyledGrid = styled(Grid)(() => ({
	margin: '0px',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(5),
	border: '1px solid #D0D7DE',
	borderRadius: 5,
	backgroundColor: '#FFFFFF',
	overflow: 'hidden',
}));

export const InvestorInfo: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentSponsor } = grants.grants;
	const [fetchTotalCommitment, totalCommitment] =
		useLazyGetInvestorTotalCommitmentQuery();
	const [capitalActivityData, setCapitalActivityData] = useState<
		ISingleCommitment[]
	>([]);
	const [showAll, setShowAll] = useState<boolean>(false);
	const [editInvestor, setEditInvestor] = useState<boolean>(false);
	const [fetchInvestorInfo, investorInfo] = useLazyGetInvestorQuery();
	const [investor, setInvestor] = useState<Investor | undefined>(undefined);
	const columnWidth: GridSize = hasPermission({
		scopes: [SCOPES.canSeeInvestorNotes],
	})
		? 4
		: 6;

	useEffect(() => {
		if (currentInvestor.id === 0) return;
		fetchInvestorInfo(currentInvestor.id);
		fetchTotalCommitment({
			investorId: currentInvestor.id,
			sponsorId: currentSponsor.id,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor.id]);

	useEffect(() => {
		if (investorInfo.data) {
			setInvestor(investorInfo.data);
		}
	}, [investorInfo.data]);

	useEffect(() => {
		if (totalCommitment.data) {
			const info: ISingleCommitment[] = [];
			const commitmentData: ITotalCommitmentReport[] = totalCommitment.data;
			commitmentData.forEach((cData: ITotalCommitmentReport) => {
				info.push({
					fundName: cData.fund.name,
					fundId: cData.fund.id,
					amount: cData.totalCommitment
						? numberFormat(cData.totalCommitment)
						: '-',
				});
			});
			setCapitalActivityData(info);
		}
	}, [totalCommitment.data]);

	const handleAfterSave = async () => {
		if (!investor) return;
		await fetchInvestorInfo(investor.id);
		setEditInvestor(false);
	};

	if (investor?.id === 0) return <></>;

	return (
		<RootContainer>
			<StyledGrid container spacing={1}>
				<StyledGrid item xs={columnWidth} style={{ paddingLeft: 0 }}>
					<StyledPaper elevation={0} style={{ height: '24rem' }}>
						{investor && (
							<InvestorMetaData
								investor={investor}
								onEditClick={() => setEditInvestor(true)}
							/>
						)}
					</StyledPaper>
				</StyledGrid>
				<StyledGrid item xs={columnWidth} style={{ paddingRight: 0 }}>
					<StyledPaper
						elevation={0}
						style={{
							minHeight: '24rem',
							height: showAll ? 'auto' : '24rem',
							overflow: 'auto',
						}}
					>
						<CapitalActivityData commitementsToShow={capitalActivityData} />
					</StyledPaper>
				</StyledGrid>
				<PermissionsWrapper scopes={[SCOPES.canSeeInvestorNotes]}>
					<StyledGrid item xs={columnWidth}>
						<StyledPaper
							elevation={0}
							style={{
								minHeight: '24rem',
								height: showAll ? 'auto' : '24rem',
								overflow: 'auto',
							}}
						>
							<InvestorNotes investorId={investor?.id || 0} />
						</StyledPaper>
					</StyledGrid>
				</PermissionsWrapper>
			</StyledGrid>

			{editInvestor && investor && (
				<EditInvestorModal
					isOpen={editInvestor}
					onClose={() => setEditInvestor(false)}
					investor={investor}
					afterSave={handleAfterSave}
					editContactInfo={false}
				/>
			)}
		</RootContainer>
	);
};
