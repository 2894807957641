import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Paper, Typography } from '@mui/material';
import {
	BusinessCenter,
	CalendarToday,
	Label,
	Person,
} from '@mui/icons-material';
import { getDate } from './utils';
import { selectNavbarTablet } from 'api/redux/NavBarStateReducer';
import { IconBtn } from 'common/components/IconBtn';
import { BackToDocuments } from './BackToDocuments';
import { IMetadataRowProps } from './types';
import moment from 'moment';
import { documentsState } from 'api/redux/DocumentsReducer';

export const DocumentMetadata: FC = () => {
	const { currentDocument: document } = useSelector(documentsState);
	const tablet = useSelector(selectNavbarTablet);
	const docDate = (period?: string) => {
		if (!period) return '';

		let stringDate = period;

		if (stringDate.length > 10 && !isNaN(+stringDate.charAt(0)))
			stringDate = stringDate.substring(0, 10);

		return moment(getDate(stringDate)).format('MM/DD/YYYY');
	};

	const MetadataRow: FC<IMetadataRowProps> = ({
		iconComponent,
		title,
		tooltip,
	}) => {
		if (!title) return <></>;

		return (
			<Paper
				elevation={0}
				sx={{
					maxWidth: '300px',
					width: '100%',
					height: '100%',
					margin: `${tablet ? '5px 0px 0px 20px' : '0px 5px 5px 5px'}`,
					borderRadius: '5px',
					border: '1px solid #D0D7DE',
				}}
			>
				<Grid container spacing={1}>
					<Grid item xs={3}>
						<IconBtn customColor={true} tooltip={tooltip}>
							{iconComponent}
						</IconBtn>
					</Grid>
					<Grid
						item
						xs={9}
						sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
					>
						<Typography>{title}</Typography>
					</Grid>
				</Grid>
			</Paper>
		);
	};

	if (!document) return <></>;

	return (
		<Box
			sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
		>
			<Box sx={{ alignSelf: 'flex-start' }}>
				{tablet ? <BackToDocuments /> : <></>}
			</Box>
			<MetadataRow
				iconComponent={<BusinessCenter />}
				title={document?.fund?.name || document.fundName}
				tooltip="Fund name"
			/>
			<MetadataRow
				iconComponent={<Person />}
				title={document?.investor?.name || document.investorName}
				tooltip="Investor name"
			/>
			<MetadataRow
				iconComponent={<Label />}
				title={document?.type}
				tooltip="Document type"
			/>
			<MetadataRow
				iconComponent={<CalendarToday />}
				title={docDate(document?.period)}
				tooltip="Document period"
			/>
		</Box>
	);
};
