import { FC } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { IContributionsPieChart } from './DashboardTypes';

export const ContributionsPieChart: FC<IContributionsPieChart> = ({
	graphData,
	contributionPercentage,
	sponsorColor,
	fullyFunded,
	totalCommitment,
}) => {
	const theme = {
		fontFamily: 'IBMPlexSansRegular, IBMPlexSansBold, IBMPlexSansLight',
		fontSize: 13,
	};

	const labelToColor = {
		'Remaining Commitment': '#000000',
		'Total Contributed': '#FFFFFF',
	};
	return (
		<ResponsivePie
			data={graphData}
			borderWidth={1}
			borderColor={'#d1d7e0'}
			colors={['#f2f2f2', `${sponsorColor}`]}
			valueFormat="$,"
			theme={theme}
			startAngle={-contributionPercentage * 180}
			arcLabel={
				fullyFunded
					? // eslint-disable-next-line @typescript-eslint/no-unused-vars
					  (d) => 'Commitment Fully Funded'
					: (d) => {
							const percent = (d.value / totalCommitment) * 100;
							let label = '';
							if (percent < 1) label = `${Math.ceil(percent)}%`;
							if (percent > 99) label = `${Math.floor(percent)}%`;
							if (percent < 99 && percent > 1)
								label = `${Math.round(percent)}%`;
							return label;
					  }
			}
			arcLabelsTextColor={(item) => {
				return labelToColor[item.label];
			}}
			arcLabelsSkipAngle={5}
			enableArcLinkLabels={fullyFunded ? false : true}
			arcLinkLabelsDiagonalLength={10}
			arcLinkLabelsStraightLength={10}
			arcLinkLabelsSkipAngle={2}
			innerRadius={contributionPercentage + (fullyFunded ? 0.2 : -0.1)}
			padAngle={fullyFunded ? 0 : 1}
			cornerRadius={5}
			margin={{
				top: 20,
				bottom: 20,
				right: 20,
				left: 20,
			}}
			activeInnerRadiusOffset={4}
			activeOuterRadiusOffset={4}
			motionConfig={'slow'}
		/>
	);
};

export default ContributionsPieChart;
