import ICapitalActivity, {
	ICapitalActivityData,
} from './capital-activity.types';
import { IFundCapitalActivity } from './fund-capital-activity.types';
import {
	IRow,
	IColumn,
	INullBehavior,
	ICapitalActivityTemplate,
} from './types';

const isZero = (val: number | string | undefined | null): boolean => {
	if (val === undefined || val === null) return true;

	const numValue: number = typeof val === 'number' ? val : parseInt(val);

	return Math.abs(numValue) === 0;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	currencySign: 'accounting',
	maximumFractionDigits: 0,
});

export const formatCurrency = (
	value: string | number,
	nullBehavior: INullBehavior,
): string => {
	if (isZero(value)) return nullValue(nullBehavior);

	return currencyFormatter.format(Number(value));
};

export const numberFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	currencySign: 'accounting',
	maximumFractionDigits: 0,
});

const nullValue = (nullBehavior: INullBehavior): string => {
	switch (nullBehavior) {
		case INullBehavior.DASH:
			return '-';
		case INullBehavior.DOLLAR_SIGN_AND_DASH:
			return '$ -';
		case INullBehavior.BLANK:
			return '';
		default:
			return '';
	}
};

export const formatNumber = (
	value: number | string,
	nullBehavior: INullBehavior,
): string => {
	if (isZero(value)) return nullValue(nullBehavior);

	return numberFormatter.format(Number(value)).replace('$', '');
};

export const borderBottomSx = (
	row: IRow,
	column: IColumn,
	template: string,
	sponsorColor: string,
): string => {
	if (
		row.valueFormats.includes('UNDERLINE') &&
		column.timePeriodSuffix.includes('PTD') &&
		row.label.text.includes('Contributions') &&
		template.includes('PE - Inception')
	)
		return '0';
	if (
		row.valueFormats.includes('DOUBLE_UNDERLINE') &&
		column.timePeriodSuffix.includes('PTD') &&
		row.label.text.includes('Remaining Unfunded Commitment') &&
		template.includes('PE - Inception')
	)
		return '0';

	if (row.valueFormats.includes('UNDERLINE'))
		return `solid 1.25px ${sponsorColor}`;
	if (row.valueFormats.includes('DOUBLE_UNDERLINE'))
		return `double 3.75px ${sponsorColor}`;
	if (row.valueFormats.includes('THICK_UNDERLINE')) return 'solid 3px';
	return '0';
};

export const getInvestorColumns = (
	template: ICapitalActivityTemplate,
): IColumn[] => {
	return template.columns.filter(
		(column) =>
			column.title[0].text && column.title[0].text.includes('Investor Level'),
	);
};

export const getFundColumns = (
	template: ICapitalActivityTemplate,
): IColumn[] => {
	return template.columns.filter(
		(column) =>
			column.title[0].text && column.title[0].text.includes('Fund Level'),
	);
};

export const getCellData = (
	row: IRow,
	column: IColumn,
	template: string,
	attributeData: ICapitalActivityData[],
	fundData: IFundCapitalActivity | undefined,
): string | null | undefined => {
	const { timePeriodSuffix } = column;
	let cell;

	if (
		row.label.text == 'Contributions' &&
		template.includes('PE - No Inception') &&
		row.label.formats.includes('INDENT') &&
		column.title[0].text === 'Investor Level'
	) {
		cell = attributeData.find((f) => f.name === `${row.label.text} (ITD)`);
		return formatCell(row, column, cell, template);
	}

	if (
		row.label.text == 'Contributions' &&
		template.includes('PE - No Inception') &&
		!row.label.formats.includes('INDENT') &&
		column.title[0].text === 'Investor Level'
	) {
		cell = attributeData.find((f) => f.name === `${row.label.text} (YTD)`);
		return formatCell(row, column, cell, template);
	}

	if (
		row.label.text == 'Contributions' &&
		template.includes('PE - No Inception') &&
		row.label.formats.includes('INDENT') &&
		column.title[0].text === 'Fund Level'
	) {
		cell = fundData?.attributeData.find(
			(f) => f.name === `${row.label.text} (ITD)`,
		);
		return formatCell(row, column, cell, template);
	}

	if (
		row.label.text == 'Contributions' &&
		template.includes('PE - No Inception') &&
		!row.label.formats.includes('INDENT') &&
		column.title[0].text === 'Fund Level'
	) {
		cell = fundData?.attributeData.find(
			(f) => f.name === `${row.label.text} (YTD)`,
		);
		return formatCell(row, column, cell, template);
	}

	if (column.title[0].text && column.title[0].text === 'Investor Level') {
		switch (timePeriodSuffix) {
			case 'ITD':
				cell =
					attributeData.find((f) => f.name === `${row.label.text} (ITD)`) ||
					attributeData.find((f) => f.name === `${row.label.text}`);
				break;
			case 'YTD':
				cell =
					attributeData.find((f) => f.name === `${row.label.text} (YTD)`) ||
					attributeData.find((f) => f.name === `${row.label.text} (PTD)`);
				break;
			case 'PTD':
				cell =
					attributeData.find((f) => f.name === `${row.label.text} (PTD)`) ||
					attributeData.find((f) => f.name === `${row.label.text} (YTD)`) ||
					attributeData.find((f) => f.name === `${row.label.text}`);
				break;
			case 'None':
				cell = attributeData.find((f) => f.name === `${row.label.text}`);
				break;
			default:
				cell = 'Template Error';
		}
	}

	if (column.title[0].text && column.title[0].text === 'Fund Level') {
		switch (timePeriodSuffix) {
			case 'ITD':
				cell =
					fundData?.attributeData.find(
						(f) => f.name === `${row.label.text} (ITD)`,
					) ||
					fundData?.attributeData.find((f) => f.name === `${row.label.text}`);
				break;
			case 'YTD':
				cell =
					fundData?.attributeData.find(
						(f) => f.name === `${row.label.text} (YTD)`,
					) ||
					fundData?.attributeData.find(
						(f) => f.name === `${row.label.text} (PTD)`,
					);
				break;
			case 'PTD':
				cell =
					fundData?.attributeData.find(
						(f) => f.name === `${row.label.text} (PTD)`,
					) ||
					fundData?.attributeData.find(
						(f) => f.name === `${row.label.text} (YTD)`,
					) ||
					fundData?.attributeData.find((f) => f.name === `${row.label.text}`);
				break;
			case 'None':
				cell = fundData?.attributeData.find(
					(f) => f.name === `${row.label.text}`,
				);
				break;
			default:
				cell = 'Template Error';
		}
	}

	return formatCell(row, column, cell, template);
};

export const formatCell = (
	row: IRow,
	column: IColumn,
	cell: ICapitalActivityData,
	template: string,
): string | undefined => {
	if (!cell) return nullValue(row.nullBehavior);

	if (cell?.value && row.valueFormats.includes('CURRENCY')) {
		if (
			column.timePeriodSuffix == 'PTD' &&
			row.label.text.includes('Total Commitment') &&
			row.label.formats.includes('INDENT') &&
			template.includes('PE - Inception')
		) {
			return undefined;
		} else if (
			column.timePeriodSuffix == 'PTD' &&
			row.label.text.includes('Remaining Unfunded Commitment') &&
			row.label.formats.includes('INDENT') &&
			template.includes('PE - Inception')
		) {
			return undefined;
		} else if (
			column.timePeriodSuffix == 'PTD' &&
			row.label.text.includes('Contributions Receivable') &&
			row.label.formats.includes('INDENT') &&
			template.includes('PE - Inception with Contributions Receivable')
		) {
			return undefined;
		} else {
			return formatCurrency(cell.value, row.nullBehavior);
		}
	}

	if (cell?.value && row.valueFormats.includes('NUMBER')) {
		// yes this is messy, its changing values based on templates until we can iron out what we want to do about the templates
		if (template.includes('PE - Inception')) {
			if (
				row.label.text.includes('Contributions') &&
				!row.label.text.includes('Receivable') &&
				row.label.formats.includes('INDENT') &&
				column.timePeriodSuffix != 'PTD'
			) {
				if (isZero(cell.value)) return '-';

				return formatNumber(-cell.value, row.nullBehavior);
			} else if (
				column.timePeriodSuffix == 'PTD' &&
				row.label.text.includes('Contributions') &&
				row.label.formats.includes('INDENT')
			) {
				return undefined;
			} else {
				return formatNumber(cell.value, row.nullBehavior);
			}
		} else if (template.includes('PE - No Inception')) {
			if (
				row.label.text.includes('Contributions') &&
				!row.label.text.includes('Receivable') &&
				row.label.formats.includes('INDENT')
			) {
				return formatNumber(-cell.value, row.nullBehavior);
			} else {
				return formatNumber(cell.value, row.nullBehavior);
			}
		}
		if (
			row.label.text.includes('Contributions') &&
			row.label.formats.includes('INDENT') &&
			column.timePeriodSuffix != 'PTD'
		) {
			return formatNumber(-cell.value, row.nullBehavior);
		} else if (
			column.timePeriodSuffix == 'PTD' &&
			row.label.text.includes('Contributions') &&
			row.label.formats.includes('INDENT')
		) {
			return undefined;
		} else {
			return formatNumber(cell.value, row.nullBehavior);
		}
	}

	if (cell?.value && row.valueFormats.includes('PERCENTAGE'))
		return `${parseInt(cell.value) * 100}%`;
	if (cell?.value) return cell.value;
	if (
		!cell.value &&
		[
			'Contributions',
			'Total Commitment',
			'Remaining Unfunded Commitment',
		].includes(row.label.text) &&
		row.label.formats.includes('INDENT') &&
		column.timePeriodSuffix === 'PTD'
	)
		return '';
	return '-';
};

export const getDateStringFromReportingPeriod = (
	reportingPeriod: string,
): string => {
	if (!reportingPeriod) {
		return '';
	}

	return reportingPeriod.replace('Period ending ', '').replace(' - DRAFT', '');
};

const getTime = (date?: Date) => {
	return date != null ? date.getTime() : 0;
};

export const getSortedCapitalActivityData = (
	capitalActivityData: ICapitalActivity[],
): ICapitalActivity[] => {
	return [...capitalActivityData]
		.map((activity) => ({
			...activity,
			newReportingPeriod: new Date(
				getDateStringFromReportingPeriod(activity.reportingPeriod),
			),
		}))
		.sort(
			(a, b) => getTime(b.newReportingPeriod) - getTime(a.newReportingPeriod),
		);
};
