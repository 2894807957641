import { Close } from '@mui/icons-material';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Grid,
	Box,
	Typography,
	Select,
	MenuItem,
	Alert,
} from '@mui/material';
import { BrandedButton } from 'common/components/BrandedButton';
import { FC, useEffect, useState } from 'react';
import {
	INotifyDocumentModalProps,
	ITitleBarProps,
	NotificationOptions,
	notificationOptionNames,
} from './types';
import {
	EManualNotificationType,
	useManuallyNotifyMutation,
} from 'api/redux/services/documentApi';
import { documentLevels } from 'modules/admin/UploadDocuments/types';
import { constants } from 'common/helpers/Constants';
import { EDocumentLevels } from 'modules/admin/UploadDocuments/types';

export const TitleBar: FC<ITitleBarProps> = ({ onClose }) => {
	return (
		<Grid container direction="row">
			<Grid item xs={10} md={10}>
				<Box>
					<Typography variant="h4" sx={{ fontSize: '1.6rem' }}>
						Notify Users on this Document
					</Typography>
				</Box>
			</Grid>

			<Grid item xs={2} md={2}>
				<Box display="flex" justifyContent="flex-end">
					<BrandedButton variant="text" onClick={onClose}>
						<Close />
					</BrandedButton>
				</Box>
			</Grid>
		</Grid>
	);
};

export const NotifyDocumentModal: FC<INotifyDocumentModalProps> = ({
	document,
	open,
	handleClose,
}) => {
	const [selectedOption, setSelectedOption] = useState<NotificationOptions>(
		NotificationOptions.CONFIRM_RECEIPT,
	);
	const [notify, { isLoading, isError, isSuccess }] =
		useManuallyNotifyMutation();
	const docLevel = document?.table?.toLowerCase().includes('fund')
		? EDocumentLevels.FUND_LEVEL
		: EDocumentLevels.INVESTOR_LEVEL;
	const documentLevel = documentLevels.find((dl) => dl.name === docLevel);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	if (!documentLevel) return <></>;

	const handleConfirmReceipt = () => {
		if (!document) return;

		notify({
			documentId: document.id,
			notificationType: EManualNotificationType.CONFIRM_RECEIPT,
			documentLevelId: documentLevel.id,
		});
	};

	const handleRemind = () => {
		if (!document) return;

		notify({
			documentId: document.id,
			notificationType: EManualNotificationType.REMINDER,
			documentLevelId: documentLevel.id,
		});
	};

	const handleNotify = () => {
		const actions = {
			[NotificationOptions.CONFIRM_RECEIPT]: handleConfirmReceipt,
			[NotificationOptions.REMINDER]: handleRemind,
		};

		return actions[selectedOption]();
	};

	useEffect(() => {
		if (!isError) return;

		setError(true);
	}, [isError]);

	useEffect(() => {
		if (!isSuccess) return;

		setSuccess(true);
	}, [isSuccess]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="doc-notif-alert-dialog"
			aria-describedby="doc-notif-alert-dialog-description"
		>
			<DialogTitle id="doc-notif-alert-dialog-title">
				<TitleBar onClose={handleClose} />
			</DialogTitle>
			<DialogContent sx={{ pt: 0, pb: 2 }}>
				<DialogContentText id="doc-notif-alert-dialog-description">
					Please choose between the options below
				</DialogContentText>
				<Select
					id="notification-options-select"
					value={selectedOption}
					onChange={(e) => {
						setSelectedOption(e.target.value as NotificationOptions);
					}}
					fullWidth
				>
					{Object.values(NotificationOptions).map(
						(option: NotificationOptions) => (
							<MenuItem key={option} value={option}>
								{notificationOptionNames[option]}
							</MenuItem>
						),
					)}
				</Select>
			</DialogContent>
			<DialogActions sx={{ pt: 0, pb: 2 }}>
				<BrandedButton
					variant="contained"
					onClick={handleNotify}
					disabled={isLoading}
				>
					{isLoading ? 'Please wait...' : 'Notify'}
				</BrandedButton>
			</DialogActions>
			{error && (
				<Alert severity="error" onClose={() => setError(false)}>
					{`There was an error. Please try again or contact ${constants.SUPPORT_EMAIL}`}
				</Alert>
			)}
			{success && (
				<Alert severity="success" onClose={() => setSuccess(false)}>
					Notifications were sent!
				</Alert>
			)}
		</Dialog>
	);
};
