import { ReactElement, useEffect, useState } from 'react';
import { Home } from '../Home';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { useAuth0 } from '@auth0/auth0-react';
import { saveToken } from './utils';
import { Alert } from '@mui/material';

type AuthWrapperProps = {
	children: ReactElement<unknown>;
};

const AuthWrapper = ({ children }: AuthWrapperProps): JSX.Element => {
	const { isAuthenticated, isLoading, error, getAccessTokenSilently } =
		useAuth0();
	const [success, setSuccess] = useState<boolean>(false);

	useEffect(() => {
		if (isAuthenticated) {
			getAccessTokenSilently()
				.then((token) => {
					saveToken(token);
					setSuccess(true);
				})
				.catch(() => setSuccess(false));
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (error) {
			console.error(`Auth0 error: ${error.message}`);
		}
	}, [error]);

	if (isLoading) return <LoadingSpinner />;
	if (!isAuthenticated) return <Home />;
	if (error)
		return (
			<Alert severity="error" variant="filled">
				An error occurred while trying to get the access token from Auth0
			</Alert>
		);

	return success ? children : <LoadingSpinner />;
};

export default AuthWrapper;
