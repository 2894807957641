import { SCOPES } from '../Scopes';

export const SponsorUserPermissions: SCOPES[] = [
	/** Side Menu Scopes */
	SCOPES.canSeeOrganizationUsersOption,
	SCOPES.canSeeInvestorUsersOption,
	SCOPES.canSeeReportsMenuOption,
	SCOPES.canSeeOrganizationTab,
	SCOPES.canSeeInvestorTab,

	/** Investor scopes */
	SCOPES.canSeeInvestorsColumn,
	SCOPES.canSeeInvestorPermissionsColumn,
	SCOPES.canSeeInvestorLastLoginColumn,
	SCOPES.canSeeInvestorInfo,
	SCOPES.canSeeInvestorNotes,
	SCOPES.canEditInvestorUserTags,

	/** User scopes */
	SCOPES.canDownloadUsers,

	/** Email Template scopes */
	SCOPES.canSeeEmailTemplates,
];
