import { Box, Grid, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { IInvestorMetadataProps } from './types';
import { styled } from '@mui/system';

const InvestorTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	fontWeight: 'bold',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '1.5rem',
	marginTop: '-.5rem',
}));

const DataTitles = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	marginTop: '1rem',
}));

const InvestorTypographyData = styled(Typography)(({ theme }) => ({
	marginTop: '1rem',
	marginLeft: '-4.5rem',
	wordBreak: 'break-all',
	whiteSpace: 'normal',
}));

const EditIconButton = styled(IconButton)(({ theme }) => ({
	float: 'right',
}));

export const InvestorMetaData: FC<IInvestorMetadataProps> = ({
	investor,
	onEditClick,
}) => {
	return (
		<>
			<InvestorTitle>
				<Typography variant="h4" display="inline">
					{investor.name}
				</Typography>
				<PermissionsWrapper scopes={[SCOPES.canEditInvestorInfo]}>
					<EditIconButton color="inherit" onClick={onEditClick}>
						<EditIcon />
					</EditIconButton>
				</PermissionsWrapper>
			</InvestorTitle>

			<Grid container spacing={2}>
				<Grid item xs={6}>
					<DataTitles>Phone: </DataTitles>
				</Grid>
				<Grid item xs={6}>
					<InvestorTypographyData>{investor.phone}</InvestorTypographyData>
				</Grid>
				<Grid item xs={6}>
					<DataTitles>Email: </DataTitles>
				</Grid>
				<Grid item xs={6}>
					<InvestorTypographyData>{investor.email}</InvestorTypographyData>
				</Grid>
				<Grid item xs={6}>
					<DataTitles>Address: </DataTitles>
				</Grid>
				<Grid item xs={6}>
					<InvestorTypographyData>
						{investor.addressLine1}
						{investor.addressLine1 && (
							<Typography display="inline">, </Typography>
						)}
						{investor.addressLine2}
						{investor.addressLine2 && (
							<Typography display="inline">, </Typography>
						)}
						{investor.city}
						{!!investor.city && <Typography display="inline">, </Typography>}
						{investor.state}
						{!!investor.state && <Typography display="inline">, </Typography>}
						{investor.zipcode}
						{!!investor.country && <Typography display="inline">, </Typography>}
						{investor.country}
					</InvestorTypographyData>
				</Grid>
				<Grid item xs={6}>
					<DataTitles>Entity Type: </DataTitles>
				</Grid>
				<Grid item xs={6}>
					<InvestorTypographyData>{investor.entityType}</InvestorTypographyData>
				</Grid>
				<Grid item xs={6}>
					<DataTitles>Other: </DataTitles>
				</Grid>
				<Grid item xs={6}>
					<InvestorTypographyData>{investor.other}</InvestorTypographyData>
				</Grid>
			</Grid>
		</>
	);
};
