import { FC } from 'react';
import { Box, MenuItem, Select, TextField } from '@mui/material';
import { IAddUserFormParams } from './types';
import { useLocation, useResolvedPath } from 'react-router-dom';

export const AddUserForm: FC<IAddUserFormParams> = ({
	availableRoles,
	email,
	setEmail,
	fullName,
	setFullName,
	role,
	setRole,
	phone,
	setPhone,
	organization,
	setOrganization,
	title,
	setTitle,
}) => {
	const location = useLocation();
	const resolvedPath = useResolvedPath(location.pathname);
	const path = resolvedPath.pathname;

	return (
		<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
			<TextField
				label="Full Name"
				variant="outlined"
				fullWidth
				style={{
					marginBottom: '5px',
				}}
				inputProps={{
					style: {
						paddingLeft: 20,
						borderBottom: 'none',
					},
				}}
				value={fullName}
				onChange={(e) => setFullName(e.target.value)}
			/>
			<TextField
				label="Email Address"
				variant="outlined"
				fullWidth
				style={{
					marginBottom: '5px',
				}}
				inputProps={{
					style: {
						borderBottom: 'none',
						paddingLeft: 20,
					},
				}}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<TextField
				label="Phone Number"
				variant="outlined"
				fullWidth
				style={{
					marginBottom: '5px',
				}}
				inputProps={{
					style: {
						borderBottom: 'none',
						paddingLeft: 20,
					},
				}}
				value={phone}
				onChange={(e) => setPhone(e.target.value)}
			/>
			<TextField
				label="Organization"
				variant="outlined"
				fullWidth
				style={{
					marginBottom: '5px',
				}}
				inputProps={{
					style: {
						borderBottom: 'none',
						paddingLeft: 20,
					},
				}}
				value={organization}
				onChange={(e) => setOrganization(e.target.value)}
			/>
			<TextField
				label="Title"
				variant="outlined"
				fullWidth
				style={{
					marginBottom: '5px',
				}}
				inputProps={{
					style: {
						borderBottom: 'none',
						paddingLeft: 20,
					},
				}}
				value={title}
				onChange={(e) => setTitle(e.target.value)}
			/>
			<Select
				label="User Role"
				labelId="user-role-selector"
				id="user-role-selector"
				value={role}
				fullWidth
				inputProps={{
					style: {
						borderBottom: 'none',
					},
				}}
			>
				{availableRoles[path].map((r, i) => {
					return (
						<MenuItem key={i} value={r} onClick={() => setRole(r)}>
							{r}
						</MenuItem>
					);
				})}
			</Select>
		</Box>
	);
};
