import { FC, useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import {
	useLazyGetFundDocumentPdfQuery,
	useLazyGetInvestorDocumentPdfQuery,
} from 'api/redux/services/documentApi';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Box, Grid, Paper } from '@mui/material';
import { DocumentTableKeys, DocumentTables } from './types';
import { ButtonsRow } from './ButtonsRow';
import { FramedDocument } from './FramedDocument';
import { useSelector } from 'react-redux';
import { documentsState } from 'api/redux/DocumentsReducer';

export const DocumentPreview: FC = () => {
	const { currentDocument: document } = useSelector(documentsState);
	const [getInvestorDocPdf, { data: investorDocPdf }] =
		useLazyGetInvestorDocumentPdfQuery();
	const [getFundDocPdf, { data: fundDocPdf }] =
		useLazyGetFundDocumentPdfQuery();
	const [pdfUrl, setPdfUrl] = useState('');
	const tableKey =
		(document?.table as DocumentTables) === DocumentTables.FUND_DOCUMENTS
			? DocumentTableKeys.FT
			: DocumentTableKeys.IT;
	const pdfBlob =
		tableKey === DocumentTableKeys.FT ? fundDocPdf : investorDocPdf;

	useEffect(() => {
		if (!document) return;
		if (tableKey === DocumentTableKeys.FT) {
			getFundDocPdf(document.id);
			return;
		}

		getInvestorDocPdf(document.id);
	}, []);

	useEffect(() => {
		if (pdfBlob === undefined) return;

		const tmpUrl = URL.createObjectURL(pdfBlob);
		setPdfUrl(tmpUrl);

		return () => URL.revokeObjectURL(tmpUrl);
	}, [fundDocPdf, investorDocPdf]);

	if (pdfBlob === undefined) return <></>;

	return (
		<Box
			sx={{
				display: 'flex',
				height: '100%',
				width: '100%',
				justifyContent: 'center',
			}}
		>
			<Paper elevation={0}>
				<Grid
					container
					direction="column"
					sx={{
						height: '100%',
						padding: '5px',
						borderRadius: '5px',
						border: '1px solid #D0D7DE',
					}}
				>
					<Grid
						item
						xs={11}
						sx={{
							overflowX: 'auto',
							overflowY: 'auto',
							maxWidth: '-webkit-fill-available !important',
						}}
					>
						<FramedDocument pdfBlob={pdfBlob} />
					</Grid>
					<Grid
						item
						xs={1}
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<ButtonsRow docUrl={pdfUrl} />
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};
