import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Pagination, Paper, Typography } from '@mui/material';
import { selectDropdown } from 'api/redux/DropdownReducer';
import {
	useGetEmailTemplatesQuery,
	useLazyGetEmailTemplateByIdQuery,
} from 'api/redux/services/email.templates.service';
import LoadingSpinner from 'common/components/LoadingSpinner';
import CardsTable from 'common/components/CardsTable/CardsTable';
import { IAscDesc } from 'api/redux/types';
import { onSetSortStrategy } from 'api/redux/UsersReducer';
import { ESortSource } from 'common/components/CardsTable/types';
import { SearchBar } from 'common/components/SearchBar';
import { styled } from '@mui/system';
import { CreateEmailTemplateButton } from 'common/components/EmailTemplates/CreateEmailTemplateButton';
import { ColHeaders, IColumn } from './types';
import { EmailTemplate } from 'app/types/email.template.types';
import { SaveEmailTemplateModal } from './SaveEmailTemplateModal';
import { DeleteEmailTemplateModal } from './DeleteEmailTemplateModal';
import moment from 'moment';
import { EmailTemplateActions } from './EmailTemplateActions';
import { useGetOrganizationQuery } from 'api/redux/services/organizations.service';

const formatDate = (stringDate: string): string => {
	return moment(stringDate).format('MM/DD/YYYY');
};

const TableBox = styled(Box)(() => ({
	width: 'min(100%, 1300px)',
}));

const HeaderPaper = styled(Paper)(() => ({
	width: '100%',
	marginBottom: '3px',
	padding: '5px',
	display: 'flex',
	justifyContent: 'flex-end',
	border: '1px solid #e3e3e3',
}));

const LabelBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	padding: 10,
}));

const TableTypography = styled(Typography)(() => ({
	fontSize: '.85rem',
}));

export const EmailTemplateTableList: FC = () => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const currentSponsorId = grants.grants.currentSponsor.id;
	const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<
		number | undefined
	>(undefined);
	const [createEmailTemplate, setCreateEmailTemplate] =
		useState<boolean>(false);
	const [editEmailTemplate, setEditEmailTemplate] = useState<boolean>(false);
	const [deleteEmailTemplate, setDeleteEmailTemplate] =
		useState<boolean>(false);
	const [previewEmailTemplate, setPreviewEmailTemplate] =
		useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [searchString, setSearchString] = useState<string>('');
	const [getEmailTemplate, emailTemplateData] =
		useLazyGetEmailTemplateByIdQuery();
	const { data: emailTemplates, refetch: refetchEmailTemplates } =
		useGetEmailTemplatesQuery({
			sponsorId: currentSponsorId,
			/**
			 * SET TO FALSE FOR LPX-957
			 */
			hideManualNotificationTemplates: true,
		});
	const [emailTemplate, setEmailTemplate] = useState<EmailTemplate | undefined>(
		undefined,
	);
	const [loading, setLoading] = useState<boolean>(false);
	const [pages, setPages] = useState<number>(0);
	const { data: currentSponsor } = useGetOrganizationQuery(currentSponsorId);

	useEffect(() => {
		if (selectedEmailTemplateId && selectedEmailTemplateId !== 0) {
			getEmailTemplate(selectedEmailTemplateId);

			if (emailTemplateData.data?.id === selectedEmailTemplateId) {
				setEmailTemplate(emailTemplateData.data);
			}
		}
	}, [selectedEmailTemplateId]);

	useEffect(() => {
		if (emailTemplateData.data) {
			setEmailTemplate(emailTemplateData.data);
		}
	}, [emailTemplateData.data]);

	const handleSort = (column: string, ascdesc: IAscDesc) => {
		const sortableColumns: string[] = ['name', 'lastUsed', 'lastEdited'];

		if (!sortableColumns.includes(column)) return;

		dispatch(onSetSortStrategy({ column, ascdesc }));
	};

	const onEmailTemplateRowClick = (emailTemplate: EmailTemplate) => {
		setSelectedEmailTemplateId(emailTemplate.id);
	};

	const handleEditEmailTemplate = (id: number) => {
		setSelectedEmailTemplateId(id);
		setEditEmailTemplate(true);
	};

	const handleDeleteEmailTemplate = (id: number) => {
		setSelectedEmailTemplateId(id);
		setDeleteEmailTemplate(true);
	};

	const handlePreviewEmailTemplate = (id: number) => {
		setSelectedEmailTemplateId(id);
		setPreviewEmailTemplate(true);
	};

	const handleSaveClose = () => {
		setCreateEmailTemplate(false);
		setEditEmailTemplate(false);
		setPreviewEmailTemplate(false);
		setSelectedEmailTemplateId(undefined);
		setEmailTemplate(undefined);
	};

	const columns: IColumn[] = useMemo(
		() => [
			{
				Header: ColHeaders.Name,
				accessor: 'name',
				Cell: ({ row }) => (
					<Box
						sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
					>
						<TableTypography>{row.original.name}</TableTypography>
					</Box>
				),
			},
			{
				Header: ColHeaders.LastEdited,
				accessor: 'updatedAt',
				Cell: ({ row }) => (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<TableTypography>
							{row.original.updatedAt ? formatDate(row.original.updatedAt) : ''}
						</TableTypography>
					</Box>
				),
			},
			{
				Header: ColHeaders.LastEditedBy,
				accessor: 'updatedBy',
				Cell: ({ row }) => (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<TableTypography>{row.original.updatedBy}</TableTypography>
					</Box>
				),
			},
			{
				Header: ColHeaders.LastUsed,
				accessor: 'lastUsedAt',
				Cell: ({ row }) => (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<TableTypography>
							{row.original.lastUsedAt
								? formatDate(row.original.lastUsedAt)
								: ''}
						</TableTypography>
					</Box>
				),
			},
			{
				Header: ColHeaders.LastUsedBy,
				accessor: 'lastUsedBy',
				Cell: ({ row }) => (
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<TableTypography>{row.original.lastUsedBy}</TableTypography>
					</Box>
				),
			},
			{
				Header: ColHeaders.Actions,
				accessor: 'more',
				minWidth: 150,
				Cell: ({ row }) => {
					const { original: template } = row;
					return (
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								justifyContent: 'flex-end',
								overflow: 'hidden',
							}}
						>
							<EmailTemplateActions
								onEdit={() => handleEditEmailTemplate(template.id)}
								onDelete={() => handleDeleteEmailTemplate(template.id)}
								onPreview={() => handlePreviewEmailTemplate(template.id)}
								isReadOnly={template.organizationId == undefined}
							/>
						</Box>
					);
				},
			},
		],
		[],
	);

	{
		if (!emailTemplates || !columns || !columns.length) {
			return <LoadingSpinner />;
		}
	}

	return (
		<TableBox>
			<HeaderPaper elevation={0}>
				<LabelBox>
					<Typography sx={{ color: '#000000' }} variant="h5">
						Email Templates
					</Typography>
				</LabelBox>
				<SearchBar
					value={searchString}
					onChange={(val) => {
						setPage(0);
						setSearchString(val);
					}}
					onCancelSearch={() => {
						setPage(0);
						setSearchString('');
					}}
				/>
				<CreateEmailTemplateButton
					setCreateEmailTemplate={setCreateEmailTemplate}
				/>
			</HeaderPaper>
			<Box>
				<Box sx={{ width: '100%', height: '75vh', overflow: 'auto' }}>
					{loading ? (
						<LoadingSpinner />
					) : (
						<CardsTable
							columns={columns}
							data={emailTemplates}
							onSort={handleSort}
							onRowClick={(row) => onEmailTemplateRowClick(row.original)}
							sortSource={ESortSource.USERS}
						/>
					)}
				</Box>
				<Box
					sx={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					{pages > 1 ? (
						<Pagination
							count={pages}
							variant="outlined"
							onChange={(event: React.ChangeEvent<unknown>, value: number) =>
								setPage(value - 1)
							}
							sx={{
								'&.MuiPagination-root': {
									backgroundColor: 'unset !important',
									justifyContent: 'center',
									display: 'flex',
									boxShadow: 'none',
									fontFamily:
										'IBMPlexSansRegular, IBMPlexSansBold, IBMPlexSansLight',
									fontSize: 13,
								},
							}}
						/>
					) : (
						<></>
					)}
				</Box>
			</Box>

			{currentSponsor && createEmailTemplate && (
				<SaveEmailTemplateModal
					isOpen={createEmailTemplate}
					afterSave={refetchEmailTemplates}
					onClose={() => handleSaveClose()}
					sponsor={currentSponsor}
					emailTemplate={undefined}
				/>
			)}
			{emailTemplate && deleteEmailTemplate && (
				<DeleteEmailTemplateModal
					emailTemplate={emailTemplate}
					isOpen={deleteEmailTemplate}
					afterDelete={refetchEmailTemplates}
					afterClose={() => setDeleteEmailTemplate(false)}
				/>
			)}
			{editEmailTemplate && emailTemplate && currentSponsor && (
				<SaveEmailTemplateModal
					isOpen={editEmailTemplate}
					onClose={() => handleSaveClose()}
					emailTemplate={emailTemplate}
					afterSave={() => {
						setEditEmailTemplate(false);
						refetchEmailTemplates();
					}}
					sponsor={currentSponsor}
				/>
			)}
			{previewEmailTemplate && emailTemplate && currentSponsor && (
				<SaveEmailTemplateModal
					isOpen={previewEmailTemplate}
					onClose={() => handleSaveClose()}
					emailTemplate={emailTemplate}
					afterSave={() => {
						setPreviewEmailTemplate(false);
					}}
					sponsor={currentSponsor}
					isPreview={true}
				/>
			)}
		</TableBox>
	);
};
