export interface IExportModeProps {
	value: ExportModes;
	handleChange: (mode: ExportModes) => void;
}

export enum ExportModes {
	BY_INVESTOR = 'Export By Investor',
	BY_USER = 'Export By User',
}

export interface IExportProps {
	mode: ExportModes;
}

export interface IFundsListProps {
	selectedFundIds: number[];
	setSelectedFundIds: (fundIds: number[]) => void;
}

export interface ITagsListProps {
	selectedTagIds: number[];
	setSelectedTagIds: (tagIds: number[]) => void;
	disabled: boolean;
}

export interface IExportButtonProps {
	exportDisabled: boolean;
	onExportClick: () => void;
	mode: ExportModes;
}
