import { Grid } from '@mui/material';
import { FC } from 'react';
import { DocumentMetadata } from '../DocumentMetadata';
import { DocumentPreview } from '../DocumentPreview';
import { BackToDocuments } from '../BackToDocuments';

export const MobileView: FC = () => {
	return (
		<Grid
			container
			direction={'row-reverse'}
			spacing={0}
			justifyContent="flex-end"
			wrap="nowrap"
			sx={{
				height: 'calc(100vh - 85px)',
				padding: '0px 0px 0px 65px',
			}}
		>
			<Grid item sm={2} sx={{ height: '100%', padding: '5px' }}>
				<DocumentMetadata />
			</Grid>
			<Grid item sm={8} sx={{ height: '100%', padding: '5px' }}>
				<DocumentPreview />
			</Grid>
			<Grid item sm={2} sx={{ height: '100%', padding: '5px' }}>
				<BackToDocuments />
			</Grid>
		</Grid>
	);
};
