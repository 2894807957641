import { IDocumentResponse } from '@redux/services/documentApi';
import { Document } from '../../app/types/document.types';
import { Dispatch, SetStateAction } from 'react';
import { IFundGrant } from '@redux/services/userApi';

export enum DocumentTables {
	INVESTOR_DOCUMENTS = 'InvestorDocuments',
	FUND_DOCUMENTS = 'FundDocuments',
}

export enum DocumentTableKeys {
	IT = 'IT',
	FT = 'FT',
}

export const documentTableKeys = {
	[DocumentTables.INVESTOR_DOCUMENTS]: DocumentTableKeys.IT,
	[DocumentTables.FUND_DOCUMENTS]: DocumentTableKeys.FT,
};

export interface IDocumentToDelete {
	id: number;
	table: DocumentTables;
}

export interface TDocumentsListProps {
	onRefresh: () => void;
	pages: number;
}

export interface IDocumentLoaderProps {
	children: (props: TDocumentsListProps) => JSX.Element;
}

export interface IMetadataRowProps {
	iconComponent: JSX.Element;
	title?: string;
	tooltip: string;
}

export interface IDocumentPreviewProps {
	document: Document;
	tableKey: DocumentTableKeys;
}

export interface IspecificDocumentUrlParams {
	document: Document;
	withBase?: boolean;
	tableKey: DocumentTableKeys;
	investorId?: number;
}

export interface IDownloadFileParams {
	fileUrl: string;
	fileName: string;
}

export const errorMessages = {
	401: 'Oops, you are not authorized to see that document type',
	404: "Oops, we couldn't find that document.",
};

export interface IPreviewProps {
	document: Document | undefined;
}

export interface IButtonsRowProps {
	docUrl: string;
}

export interface IPDFWatermarkParams {
	url: string;
	userEmail?: string;
}

export interface INoDocumentsProps {
	documents: IDocumentResponse[];
}

export interface IDocumentColumnsParams {
	mobile: boolean;
	fundName: string;
	onDelete: (doc: Document) => void;
	onRedirect: (doc: Document) => void;
	onDownload: (doc: Document) => void;
	onPrint: (doc: Document) => void;
	onUrlCopy: (doc: Document) => void;
	onNotify: (doc: Document) => void;
}

export interface IActionButtonsProps {
	document: Document;
	onDelete: (doc: Document) => void;
	onRedirect: (doc: Document) => void;
	onDownload: (doc: Document) => void;
	onPrint: (doc: Document) => void;
	onUrlCopy: (doc: Document) => void;
	onNotify: (doc: Document) => void;
}

export interface INotifyDocumentModalProps {
	document?: Document;
	open: boolean;
	handleClose: () => void;
}

export interface ITitleBarProps {
	onClose: () => void;
}

export enum NotificationOptions {
	CONFIRM_RECEIPT = 'confirm-receipt',
	REMINDER = 'reminder',
}

export const notificationOptionNames = {
	[NotificationOptions.CONFIRM_RECEIPT]: 'Confirm Receipt',
	[NotificationOptions.REMINDER]: 'Reminder',
};

export interface TDocumentNotificationWrapperProps {
	children: JSX.Element;
	documentType?: string;
}

export const alloweDocumentTypesToManualNotifications = ['Capital Calls'];
