import { IAscDesc } from 'api/redux/types';
import { ReactElement } from 'react';
import { ColumnInstance, Column } from 'react-table';

export enum ESortSource {
	DOCUMENTS = 'documents-reducer',
	USERS = 'users-reducer',
}
export interface ICardsTableProps {
	columns: Column<Record<string, any>>[];
	data: Record<string, any>[];
	onRowClick?: (row: Record<string, any>) => void;
	globalSearchTerm?: string;
	filters?: Record<string, any>;
	onSort?: (column: string, ascdesc: IAscDesc) => void;
	sortSource: ESortSource;
	clickableRow?: boolean;
}

export interface ISortProps {
	column: ColumnInstance<Record<string, unknown>>;
	onSort?: (column: string, ascdesc: IAscDesc) => void;
	sortSource: ESortSource;
}

export interface ICardRowWrapper {
	clickable: boolean;
	children: ReactElement;
}
