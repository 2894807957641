import {
	IDocumentResponse,
	useLazyGetAvailableDocumentsTypeQuery,
	useLazyGetDocumentsQuery,
} from 'api/redux/services/documentApi';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { DocumentType } from 'app/types/documentTypes.types';
import { useLazyGetInvestorPermissionsQuery } from 'api/redux/services/investors.service';
import { IDocumentLoaderProps } from './types';
import {
	documentsState,
	onSetAllDocs,
	onSetAvailableDocTypes,
	onSetFromDocumentsView,
	onSetCurrentPage,
	onSetFromEmailNotification,
	onSetSelectedDocTypeIds,
	onSetUserAllowedDocTypes,
} from 'api/redux/DocumentsReducer';
import _ from 'lodash';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import LoadingSpinner from 'common/components/LoadingSpinner';

export const DocumentsLoader: React.FC<IDocumentLoaderProps> = ({
	children,
}) => {
	const isFirstRender = useRef(true);
	const { grants } = useSelector(selectDropdown);
	const { currentInvestor, currentFund, currentSponsor } = grants;
	const investorId = currentInvestor.id;
	const fundId = currentFund.id;
	const { isAdmin } = useGrants();
	const [getDocuments, documents] = useLazyGetDocumentsQuery();
	const [fetchAvailableDocTypes] = useLazyGetAvailableDocumentsTypeQuery();
	const [fetchInvestorPermissions, { data: userInvestorPermissions }] =
		useLazyGetInvestorPermissionsQuery();
	const [pages, setPages] = useState<number>(1);
	const dispatch = useDispatch();
	const {
		userAllowedDocTypes,
		availableDocTypes,
		selectedDocTypeIds,
		sortStrategy,
		currentPage,
		fromDocumentsView,
		fromEmailNotification,
	} = useSelector(documentsState);
	const [prevSponsorId, setPrevSponsorId] = useState<number>(currentSponsor.id);

	const fetchDocTypesForUserInvestor = async (): Promise<DocumentType[]> => {
		const { data: docTypes } = await fetchAvailableDocTypes({
			fundId,
			investorId,
		});

		return docTypes ?? [];
	};

	/**
	 * Available document types for the current user
	 */
	const userDTTypes = (
		availDocTypes: DocumentType[],
		userDocTypes: DocumentType[],
	): DocumentType[] => {
		return availDocTypes?.filter((dt: DocumentType) =>
			userDocTypes.map((t) => t.id).includes(dt.id),
		);
	};

	/**
	 * Available document type ids for the current user
	 */
	const userDTTypeIds = (
		availDocTypes: DocumentType[],
		userDocTypes: DocumentType[],
	): number[] =>
		(isAdmin ? availDocTypes : userDTTypes(availDocTypes, userDocTypes))?.map(
			(dt) => dt.id,
		);

	/**
	 * Get documents calling the API
	 */
	const onRefresh = useCallback(
		() =>
			getDocuments({
				fundId: currentFund.id,
				investorId: currentInvestor.id,
				page: currentPage,
				documentTypeIds: selectedDocTypeIds,
				sortStrategy: sortStrategy,
			}),
		[
			currentFund.id,
			currentInvestor.id,
			currentPage,
			selectedDocTypeIds,
			sortStrategy,
			getDocuments,
		],
	);

	/**
	 * Returns the user allowed document types depending on the found available document types
	 * and the user permissions
	 */
	const initializeUserDocTypes = async (
		availDocTypes: DocumentType[],
	): Promise<DocumentType[]> => {
		let userDocTypes: DocumentType[] = availDocTypes;

		if (!isAdmin) {
			const { data: dt } = await fetchInvestorPermissions({
				investorId: currentInvestor.id,
			});
			userDocTypes = dt ? dt?.documentTypes : [];
		}

		return userDocTypes;
	};

	/**
	 * Whenever is the first render or the sponsor changes, we need to select all available/allowed
	 * document types
	 */
	const initializeSelectedDocTypes = (
		availDocTypes: DocumentType[],
		userDocTypes: DocumentType[],
	) => {
		if (!isFirstRender.current && prevSponsorId === currentSponsor.id) return;

		dispatch(
			onSetSelectedDocTypeIds(userDTTypeIds(availDocTypes, userDocTypes)),
		);

		if (prevSponsorId !== currentSponsor.id)
			setPrevSponsorId(currentSponsor.id);

		if (isFirstRender.current) isFirstRender.current = false;
	};

	/**
	 * Get all available document types for a given combination of fundId and investorId
	 */
	const fetchDoctypes = async (): Promise<void> => {
		const availDocTypes = await fetchDocTypesForUserInvestor();
		const userDocTypes = await initializeUserDocTypes(availDocTypes);

		dispatch(onSetAvailableDocTypes(availDocTypes));
		dispatch(
			onSetUserAllowedDocTypes(userDTTypes(availDocTypes, userDocTypes)),
		);
		dispatch(onSetCurrentPage(0));
		initializeSelectedDocTypes(availDocTypes, userDocTypes);
	};

	const memoizedChildren = useCallback(
		({ onRefresh, pages }) =>
			children({
				onRefresh,
				pages,
			}),
		[children, onRefresh, pages],
	);

	/**
	 * When sponsor, fund or investor changes, first go configure the document types
	 */
	useEffect(() => {
		if (fundId === 0 || investorId === 0) return;

		if (fromDocumentsView && !fromEmailNotification) {
			dispatch(onSetFromDocumentsView(false));
			return;
		}

		if (fromEmailNotification) dispatch(onSetFromEmailNotification(false));

		fetchDoctypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentFund.id, currentInvestor.id, currentSponsor.id]);

	/**
	 * When switching fund, investor, sponsor, page, sort or filters, go fetch the documents again
	 */
	useEffect(() => {
		if (investorId === 0 || fundId === 0) return;

		dispatch(onSetAllDocs([]));
		setPages(0);
		documents.data = { pages: 0, data: [] };
		onRefresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentInvestor.id,
		currentFund.id,
		currentPage,
		selectedDocTypeIds,
		sortStrategy,
	]);

	/**
	 * When the getDocuments query gets responded, filter the documents to show only those that are allowed
	 * and set the amount of pages to show in the pagination component
	 */
	useEffect(() => {
		if (
			!documents.data ||
			_.isEmpty(availableDocTypes) ||
			(!isAdmin && _.isEmpty(userAllowedDocTypes))
		)
			return;

		const { data: docs, pages } = documents.data;

		if (!docs) return;

		const documentsToShow: IDocumentResponse[] = docs.filter(
			(doc: IDocumentResponse) =>
				userDTTypeIds(availableDocTypes, userAllowedDocTypes)?.includes(
					doc.documentTypeId,
				),
		);

		dispatch(onSetAllDocs(documentsToShow));
		setPages(pages);
	}, [
		availableDocTypes,
		dispatch,
		documents.data,
		isAdmin,
		userAllowedDocTypes,
		userInvestorPermissions?.documentTypes,
	]);

	if (!isAdmin && currentInvestor.id === 0) return <LoadingSpinner />;

	return (
		<>
			{memoizedChildren({
				onRefresh,
				pages,
			})}
		</>
	);
};
