import { FC } from 'react';
import { IInvestorContactInfoProps } from './types';
import { Typography, Grid, TextField, Box } from '@mui/material';
import { styled } from '@mui/system';

const SectionTitle = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	marginTop: '1rem',
}));

const ControlsBox = styled(Grid)(({ theme }) => ({
	marginTop: '2rem',
}));

export const InvestorContactInfo: FC<IInvestorContactInfoProps> = ({
	show,
	investor,
	phone,
	setPhone,
	email,
	setEmail,
	addressLine1,
	setAddressLine1,
	addressLine2,
	setAddressLine2,
	city,
	setCity,
	selectedState,
	setSelectedState,
	selectedCountry,
	setSelectedCountry,
	zipcode,
	setZipcode,
	entityType,
	setEntityType,
	other,
	setOther,
}) => {
	if (!show) return <></>;

	return (
		<>
			<SectionTitle variant="subtitle1">Contact Information</SectionTitle>
			<ControlsBox container spacing={2}>
				<Grid item xs={6}>
					<TextField
						autoFocus={investor?.id !== undefined}
						label="Phone"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={phone || ''}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Email"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						type="email"
						value={email || ''}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</Grid>
			</ControlsBox>

			<SectionTitle variant="subtitle1">Address</SectionTitle>
			<ControlsBox container spacing={2}>
				<Grid item xs={6}>
					<TextField
						label="Address Line 1"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={addressLine1 || ''}
						onChange={(e) => setAddressLine1(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Address Line 2"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={addressLine2 || ''}
						onChange={(e) => setAddressLine2(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="City"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={city || ''}
						onChange={(e) => setCity(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="State"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={selectedState || ''}
						onChange={(e) => setSelectedState(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Zip Code"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={zipcode || ''}
						onChange={(e) => setZipcode(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Country"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={selectedCountry || ''}
						onChange={(e) => setSelectedCountry(e.target.value)}
					/>
				</Grid>
			</ControlsBox>
			<ControlsBox container spacing={2}>
				<Grid item xs={6}>
					<TextField
						label="Entity Type"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={entityType || ''}
						onChange={(e) => setEntityType(e.target.value)}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Other"
						variant="outlined"
						fullWidth
						inputProps={{
							style: {
								borderBottom: 'none',
								paddingLeft: 20,
							},
						}}
						value={other || ''}
						onChange={(e) => setOther(e.target.value)}
					/>
				</Grid>
			</ControlsBox>
		</>
	);
};
